import React, { useState, useEffect, Fragment } from 'react';
import { DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material/';
import NxButton from '../controls/NxButton';
import PropTypes from 'prop-types';
import NxIconButton from '../controls/NxIconButton';
import CloseIcon from '@mui/icons-material/Close';
import './scss/nx_dialog.scss';

const NxDialog = (props) => {
   const [open, setOpen] = useState(props.open ? props.open : false);

   const handleClose = (_event, reason) => {
      if (reason && reason === 'backdropClick') {
         return;
      }
      if (props.handleClose) {
         props.handleClose();
      }
      setOpen(false);
   };

   useEffect(() => {
      setOpen(props.open);
   }, [props.open]);

   const handleButtons = () => {
      if (props.buttons && Array.isArray(props.buttons) && props.buttons.length) {
         return props.buttons.map((button, index) => {
            const currentButton = button;
            const isDisabled = button.disabled;

            return (
               <NxButton
                  disabled={isDisabled}
                  key={index}
                  label={currentButton.label ? currentButton.label : ''}
                  variant={currentButton.variant ? currentButton.variant : index === 0 ? 'text' : 'text'}
                  onClick={currentButton.onClick ? async () => await currentButton.onClick() : () => {}}
               />
            );
         });
      } else return null;
   };

   return (
      <Dialog
         open={open}
         onClose={(event, reason) => handleClose(event, reason)}
         className={'nx_dialog'}
         fullScreen={props.fullScreen}
         fullWidth={props.fullWidth}
         maxWidth={props.maxWidth ? props.maxWidth : 'md'}
         sx={
            props.sx
               ? props.sx
               : {
                    '& .MuiDialog-container': {
                       '& .MuiPaper-root': {
                          width: '100%',
                          height: props.height ? props.height : 'auto',
                          maxWidth: props.width,
                          maxHeight: props.maxHeight ? props.maxHeight : 'auto'
                       }
                    }
                 }
         }
      >
         <div style={{ position: 'absolute', top: '0', right: '0' }}>
            <NxIconButton onClick={() => handleClose()}>
               <CloseIcon />
            </NxIconButton>
         </div>
         <DialogTitle component="div" sx={{ paddingBottom: '0' }}>
            {props.header ? (
               props.header
            ) : (
               <Fragment>
                  <h1 style={{ textTransform: 'capitalize', fontSize: '26px' }}>{props.title ? props.title : ''}</h1>
                  <h4
                     style={{
                        fontSize: '16px',
                        textAlign: 'left',
                        width: '75%',
                        color: 'rgb(242, 175, 92)'
                     }}
                  >
                     {props.helper ? props.helper : ''}
                  </h4>
               </Fragment>
            )}
         </DialogTitle>
         <DialogContent
            sx={props.contentSx ? Object.assign({ overflowY: 'auto' }, props.contentSx) : { overflowY: 'auto' }}
         >
            {props.children ? props.children : null}
         </DialogContent>
         <DialogActions>
            {props.actions ? props.actions : null}
            <div className="uf_form_buttons">{handleButtons()}</div>
         </DialogActions>
      </Dialog>
   );
};

NxDialog.prototype = {
   open: PropTypes.bool,
   title: PropTypes.string,
   handleClose: PropTypes.func,
   buttons: PropTypes.array,
   sx: PropTypes.object
};

export default NxDialog;
