import datastructure from './DataConfigs';
import DataItem from './DataItem';
import DataReferences from './DataReferences';

class DataProvider {
   makeRequest = null;

   datastructure = null;

   tables = {};

   referenceProvider = null;

   constructor() {
      this.referenceProvider = new DataReferences(this);
      this.datastructure = datastructure;
      const tables = Object.keys(this.datastructure);
      tables.forEach((table) => {
         this.datastructure[table].new = () => {
            return new DataItem(this, this.datastructure[table]);
         };

         this.datastructure[table].TableName = table;

         this.datastructure[table.toLowerCase()] = this.datastructure[table];
         this.tables[table] = table.toLowerCase();
      });
      global.UF.data_structure = this.datastructure;
   }

   get = (table, filters, callBack, createNewDataItem = true) => {
      global.UF.makeRequest(
         'GET',
         '/api/models/' + table,
         filters,
         true,
         (data) => {
            const dataRes = data.body ? (data.body.data ? data.body.data : data.body) : data;

            let rowCount = null;

            const results = dataRes.map((item) => {
               if (createNewDataItem) {
                  return new DataItem(this, this.datastructure[table], item);
               } else {
                  return item;
               }
            });

            if (callBack) {
               callBack(results, data.body.total ? data.body.total : 0);
            }
         },
         (err) => console.error('error', err)
      );
   };

   get_v2 = async (table, filters, createNewDataItem = true) => {
      return new Promise(async (resolve, reject) => {
         try {
            const data = await global.UF.makeRequest_v2(`GET`, `/api/models/${table}`, filters, true);
            const dataRes = data.body ? (data.body.data ? data.body.data : data.body) : data;
            let rowCount = null;
            const results = dataRes.map((item) => {
               if (createNewDataItem) {
                  return new DataItem(this, this.datastructure[table], item);
               } else {
                  return item;
               }
            });
            resolve(results, data.body.total ? data.body.total : 0);
         } catch (err) {
            console.error('Err', err);
            reject(err);
         }
      });
   };

   syncReferenceAfterSave = (table, callback) => {
      const reference_configs = this.referenceProvider.reference_configs;
      if (
         Object.keys(reference_configs).findIndex((rc) => rc == table && reference_configs[rc].type == 'remote') > -1
      ) {
         this.referenceProvider.references[table] = null;
         this.referenceProvider.get(
            table,
            () => {
               if (callback) {
                  callback();
               }
            },
            true
         );
      } else {
         if (callback) {
            callback();
         }
      }
   };

   save = (table, dataItem, callBack) => {
      const method = dataItem.isNew ? 'POST' : 'PUT';

      let url = '/api/models/' + table.toLowerCase();
      if (dataItem.isNew == false) {
         url += '?id=' + dataItem.id;
      }

      global.UF.makeRequest(
         method,
         url,
         dataItem.GetChangedFields(),
         true,
         (data) => {
            const dataRes = data.body ? data.body : data;
            this.syncReferenceAfterSave(dataItem.table_structure.TableName, () => {
               if (callBack) {
                  callBack(dataRes);
               }
            });
         },
         (err) => {
            console.log(err);
            if (dataItem.table_structure.errorHandling) {
               callBack(dataItem.table_structure.errorHandling(method, err, dataItem));
            } else {
               callBack(err);
            }

            console.error(typeof err === 'string' ? err : err.error);
         }
      );
   };

   delete = (table, dataItem, callBack) => {
      global.UF.makeRequest(
         'DEL',
         '/api/models/' + table + '?id=' + dataItem.id,
         { id: dataItem.id },
         true,
         (data) => {
            const dataRes = data.body ? data.body : data;

            this.syncReferenceAfterSave(dataItem.table_structure.TableName, () => {
               if (callBack) {
                  callBack(dataRes);
               }
            });
         },
         (err) => {
            if (dataItem.table_structure.errorHandling) {
               callBack(dataItem.table_structure.errorHandling('DEL', err, dataItem));
            } else {
               callBack(err);
            }

            console.error('error', err);
         }
      );
   };

   save_v2 = async (table, dataItem) => {
      const method = dataItem.isNew ? 'POST' : 'PUT';
      let url = '/api/models/' + table.toLowerCase();
      const fields = this.datastructure[table].fields;
      const primaryKeys = Object.keys(fields).filter((key) => fields[key].Key == 'PRI');

      if (dataItem.isNew == false) {
         url += '?';
         primaryKeys.forEach((primaryKey, index) => {
            if (index == 0) {
               url += `${primaryKey}=${dataItem[primaryKey]}`;
            } else {
               url += `&${primaryKey}=${dataItem[primaryKey]}`;
            }
         });
      }

      return new Promise(async (resolve, reject) => {
         try {
            const changed_fields = dataItem.GetChangedFields();

            if (Object.keys(changed_fields).length == 0) {
               resolve();
               return;
            }

            const data = await global.UF.makeRequest_v2(method, url, dataItem.GetChangedFields(), true);

            const dataRes = data.body ? data.body : data;
            this.syncReferenceAfterSave(dataItem.table_structure.TableName, () => {
               resolve(dataRes);
            });
         } catch (err) {
            console.error(typeof err === 'string' ? err : err.error);
            if (dataItem.table_structure.errorHandling) {
               reject(dataItem.table_structure.errorHandling(method, err, dataItem));
               return;
            }

            reject(err);
         }
      });
   };

   delete_v2 = async (table, dataItem, callBack) => {
      const url = `/api/models/${table}?id=${dataItem.id}`;

      return new Promise(async (resolve, reject) => {
         try {
            const response = await global.UF.makeRequest_v2(
               `DEL`,
               url,
               {
                  id: dataItem.id
               },
               true
            );

            if (!response.ok) {
               console.error(response);
               reject(`Error occurred while trying to delete ${table}`);
            }

            const dataRes = response.body ? response.body : response;

            this.syncReferenceAfterSave(table, () => {
               resolve(dataRes);
            });
         } catch (err) {
            if (dataItem.table_structure.errorHandling) {
               reject(dataItem.table_structure.errorHandling('DEL', err, dataItem));
               return;
            }
            reject(err);
         }
      });
   };
}

export default DataProvider;
