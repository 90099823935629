import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

export default function NxCtrlAutocomplete(props) {
   let [items, setItems] = React.useState([]);
   const [preselectedOption, setPreselectedOption] = useState(null);

   useEffect(() => {
      if (props.fieldConfig.reference) {
         fetchRef(true, () => {});
      }
   }, [props.fieldConfig?.reference]);

   useEffect(() => {
      let temp;

      if (typeof props.value === 'object') {
         temp = items.find((option) => option.id === props.value?.id);
      } else {
         temp = items.find((option) => option.id === props.value);
      }

      setPreselectedOption(temp);
   }, [props.value, items]);

   const fetchRef = (force = false, callback) => {
      global.UF.dataProvider.referenceProvider.get(
         props.fieldConfig.reference,
         (retrievedItems) => {
            retrievedItems = retrievedItems.sort((a, b) => a.label.localeCompare(b.label));
            retrievedItems = retrievedItems.filter((item) => item.label && item.label.length);
            setItems(retrievedItems);
            if (callback) {
               callback();
            }
         },
         force
      );
   };

   const onValueChange = (_e, value) => {
      if (props.fieldConfig.addable && value && value.hasOwnProperty('inputValue')) {
         createNewItem(value, (newInsertedId) => {
            fetchRef(true, () => {
               if (props.onChange) {
                  props.onChange(newInsertedId);
               }
            });
         });
      } else {
         if (props.onChange && props.dataItem) {
            props.dataItem[props.fieldConfig.name] = value ? value.id : null;
            props.onChange(props.dataItem[props.fieldConfig.name]);
         } else if (props.onChange) {
            props.onChange(value ? value.id : null);
         }
      }
   };

   const createNewItem = (dataItem, callback) => {
      const table = props.fieldConfig.reference;
      const newItem = global.UF.dataProvider.datastructure[table].new();
      newItem[props.fieldConfig.addable_field] = dataItem['inputValue'];
      newItem.Save((success) => {
         const insertId = success?.id;
         if (callback) {
            callback(insertId);
         }
      });
      return;
   };

   return (
      <Autocomplete
         value={preselectedOption || null}
         onChange={(_event, newValue) => onValueChange(_event, newValue)}
         filterOptions={(options, params) => {
            const filter = createFilterOptions();
            const filtered = filter(options, params);

            const { inputValue } = params;

            if (props.fieldConfig.addable) {
               const isExisting = options.some((option) => inputValue === option.label);
               if (inputValue !== '' && !isExisting) {
                  filtered.push({
                     inputValue,
                     label: `Add "${inputValue}"`
                  });
               }
            }

            return filtered;
         }}
         selectOnFocus
         clearOnBlur
         handleHomeEndKeys
         options={items}
         getOptionLabel={(option) => {
            if (typeof option === 'string') {
               return option;
            }
            if (option.inputValue) {
               return option.inputValue;
            }
            return option.label;
         }}
         isOptionEqualToValue={(option, value) => {
            if (value.hasOwnProperty('inputValue')) {
               return value.inputValue === option.label;
            } else {
               return option.id === value.id;
            }
         }}
         renderOption={(props, option) => <li {...props}>{option.label}</li>}
         freeSolo
         renderInput={(params) => (
            <TextField
               {...params}
               error={props.error}
               helperText={props.helperText && props.helperText.length ? props.helperText : ''}
               className={`nx_textfield nx_autocomplete ${props.className ? props.className : ''} ${
                  props.error ? 'nx_error' : ''
               }`}
               label={props.fieldConfig.label ? props.fieldConfig.label : ''}
            />
         )}
      />
   );
}
