import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import "./scss/nx_table.scss";
import withRouter from "../utils/withRouter";
import Nx_Grid_Popover from "../modals/NxGridPopover";
import Nx_Loader from "../utils/NxLoader";
import { createTheme, ThemeProvider } from "@mui/material/styles";

class Nx_Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      sortField: [],
      rowsPerPage: props.rows ? props.rows : 10,
      count: 0,
      pageNumber: 0,
      isLoading: true,
      rowsSelected: [],
      rowsExpanded: [],
      rowsForOffer: [],
      expanded_data: [],
    };

    this.fields = props.fields;
    this.config = props.config
      ? props.config
      : props.fields
      ? props.fields.map((field) =>
          typeof field == "string"
            ? global.UF.data_structure[props.table].fields[field]
            : field
        )
      : global.UF.data_structure[props.table].fieldsArr;
    this.references = {
      count: 0,
      loaded: 0,
      arr: [],
    };
  }

  fetchData = async (filters = this.props.filters) => {
    if (this.state.isLoading == false) {
      this.setState({ isLoading: true });
    }

    const qs = Object.assign(
      {
        page: this.state.pageNumber,
        size: this.state.rowsPerPage,
      },
      filters ? filters : {}
    );
    if (this.props.customFiltering) {
      this.props.customFiltering(qs);
    }

    const ds = global.UF.dataProvider.datastructure[this.props.table];

    if (ds.hasOwnProperty("filters") && ds.filters) {
      Object.keys(ds.filters).forEach((filter) => {
        qs[filter] = ds.filters[filter];
      });
    }

    if (this.state.sortField.length > 0) {
      qs.order_by = this.state.sortField[0].field;
      qs.order = this.state.sortField[0].sort;
    }

    if (this.props.syncData) {
      this.props.syncData(this.fetchData, qs);
    }

    if (this.props.handleParentFetchData) {
      const { data, total_count } = await this.props.handleParentFetchData(qs);

      this.setState({
        data: data,
        count: total_count,
        isLoading: false,
      });
      return;
    }

    global.UF.dataProvider.get(this.props.table, qs, (data, count) => {
      if (this.props.onDataItemsLoad) {
        this.props.onDataItemsLoad(data, () => {
          this.setState({ data, count, isLoading: false });
        });
      } else {
        this.setState({ data, count, isLoading: false });
      }
    });
  };

  componentDidMount = () => {
    this.state.sortField = Array.isArray(
      global.UF.data_structure[this.props.table].sorting
    )
      ? global.UF.data_structure[this.props.table].sorting
      : global.UF.data_structure[this.props.table].fieldsArr.findIndex(
          (field) => field.Field === "created_at"
        ) > -1
      ? [
          {
            field: "created_at",
            sort: "desc",
          },
        ]
      : [];
    this.props.syncData(this.fetchData);
    this.fetchReferences(true);
  };

  componentWillReceiveProps = (props) => {
    if (props.lastUpdate != this.lastUpdate) {
      this.lastUpdate = props.lastUpdate;
      this.fetchReferences(true, props.filters);
    }
  };

  fetchReferences = (force = false, filters = this.props.filters) => {
    if (this.references.count > 0) {
      this.references.loaded = 0;
      this.references.arr.forEach((ref) => {
        global.UF.dataProvider.referenceProvider.get(
          ref,
          (data) => {
            this.references[ref] = {};
            data.forEach(
              (item) => (this.references[ref][item.id] = item.label)
            );
            this.references.loaded++;
            if (this.references.loaded == this.references.count) {
              this.fetchData(filters);
            }
          },
          force
        );
      });
    } else {
      this.fetchData(filters);
    }
  };

  handleCellClick = (params) => {
    // const ds = global.UF.data_structure[this.props.table];
    // const fieldName = params.field;
    // const currentField = ds.fieldsArr.find((field) => field.Field == fieldName);
    // if (currentField && currentField.hasOwnProperty("onClick")) {
    //   currentField.onClick(params.row);
    // }
  };

  handleColumns = () => {
    const columns = this.config.map((item, key) => {
      return {
        name: item.field,
        label: item.label,
        options: {
          sort: true,
          sortDirection: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            const { rowIndex } = tableMeta;
            return item.customBodyRender
              ? item.customBodyRender(this.state.data[rowIndex])
              : value;
          },
        },
      };
    });

    columns.unshift({
      name: "children",
      options: {
        display: "excluded",
        filter: false,
      },
    });

    return columns;
  };

  renderPopOver = () => {
    return (
      <Nx_Grid_Popover
        show={this.state.popoverActive}
        buttons={this.props.popOverButtons}
        rows={this.state.activeRows}
        fetchData={this.fetchData}
      />
    );
  };

  handleRowClick = (row) => {
    return <div>{JSON.stringify(row)}</div>;
  };

  handleCheckbox = (id) => {
    const rowsForOffer = this.state.rowsForOffer;
    if (rowsForOffer.indexOf(id) > -1) {
      const elementIndex = rowsForOffer.indexOf(id);
      rowsForOffer.splice(elementIndex, 1);
    } else {
      rowsForOffer.push(id);
    }
    this.setState({ rowsForOffer }, () => {
      this.props.handleSelectionModelChange(rowsForOffer);
    });
  };

  handleRenderExpandableRow = (rowData, rowMeta) => {
    const dataItem = this.state.data[rowMeta.rowIndex];
    const temp_fields = ["isbn", "supplier_name", "type", "cost_price"];
    return this.props.expandableRowData.map((item) => {
      if (
        dataItem.isbn === item.isbn &&
        dataItem.usage_type === item.usage_type
      ) {
        return item?.children.map((item) => {
          return (
            <TableRow className="expandable_rows">
              <TableCell padding={"none"} size="small" colSpan={1}>
                <Checkbox
                  onChange={() => this.handleCheckbox(item.id)}
                  checked={this.state.rowsForOffer.indexOf(item.id) > -1}
                  style={{
                    padding: 0,
                    visibility: "hidden",
                  }}
                />
              </TableCell>
              {temp_fields.map((field) => {
                if (!item.hasOwnProperty(field)) {
                  item[field] = dataItem[field];
                }
                const value = item[field];
                return (
                  <TableCell padding={"none"} size="small" colSpan={1}>
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        });
      } else {
        return null;
      }
    });
  };

  handleOnRowSelectionChange = (currentRowsSelected) => {
    const rows = this.state.data;
    const selectedRowIndex = currentRowsSelected[0].index;
    const rowsForOffer = this.state.rowsForOffer;
    const selectedDataItem = rows[selectedRowIndex];
    if (rowsForOffer.indexOf(selectedDataItem.id) > -1) {
      const elementIndex = rowsForOffer.indexOf(selectedDataItem.id);
      rowsForOffer.splice(elementIndex, 1);
    } else {
      rowsForOffer.push(selectedDataItem.id);
    }

    const rowsSelected = this.state.rowsSelected;
    if (rowsSelected.indexOf(selectedRowIndex) > -1) {
      const elementIndex = rowsSelected.indexOf(selectedRowIndex);
      rowsSelected.splice(elementIndex, 1);
    } else {
      rowsSelected.push(selectedRowIndex);
    }

    this.setState({ rowsForOffer, rowsSelected }, () => {
      this.props.handleSelectionModelChange(rowsForOffer);
    });
  };

  handleOnRowExpansionChange = (currentRowsExpanded, allRowsExpanded) => {
    global.UF.setMaskVisibility(true);
    const currentISBNsExpanded = [];
    currentRowsExpanded.forEach((row) => {
      currentISBNsExpanded.push(this.state.data[row.index]);
    });

    if (this.props.handleExpadableRowChange) {
      this.props.handleExpadableRowChange(currentISBNsExpanded);
      global.UF.setMaskVisibility(false);
      return;
    }
  };

  handleMuiTableTheme = () => {
    return createTheme({
      components: {
        MUIDataTableFilterList: {
          styleOverrides: {
            root: {
              display: "none",
            },
          },
        },
      },
    });
  };

  render() {
    return (
      <>
        {this.state.popoverActive && this.renderPopOver()}
        {this.state.isLoading && <Nx_Loader />}
        <ThemeProvider theme={this.handleMuiTableTheme()}>
          <MUIDataTable
            className="nx_table_wrapper"
            data={this.state.data}
            columns={this.handleColumns()}
            components={this.components}
            options={{
              serverSide: true,
              // responsive: "vertical",
              pagination: true,
              rowsPerPage: this.state.rowsPerPage,
              count: this.state.count,
              page: this.state.pageNumber,
              rowsSelected: this.state.rowsSelected,
              selectableRowsHeader: false,
              expandableRows: true,
              selectableRowsHideCheckboxes: true,
              renderExpandableRow: (rowData, rowMeta) =>
                this.handleRenderExpandableRow(rowData, rowMeta),
              textLabels: {
                noMatch: "Loading...",
                body: {
                  columnHeaderTooltip: (column) => column.label,
                },
              },

              onChangePage: (newPage) => {
                this.setState({ pageNumber: newPage, isLoading: true }, () => {
                  this.fetchData();
                });
              },
              onChangeRowsPerPage: (number) => {
                this.setState({ rowsPerPage: number, isLoading: true }, () => {
                  this.fetchData();
                });
              },
              onRowExpansionChange: (
                currentRowsExpanded,
                allRowsExpanded,
                rowsExpanded
              ) =>
                this.handleOnRowExpansionChange(
                  currentRowsExpanded,
                  allRowsExpanded,
                  rowsExpanded
                ),
              onRowSelectionChange: (
                currentRowsSelected,
                allRowsSelected,
                rowsSelected
              ) =>
                this.handleOnRowSelectionChange(
                  currentRowsSelected,
                  allRowsSelected,
                  rowsSelected
                ),
              onRowClick: (params) => this.handleRowClick(params),
              onCellClick: (params) => this.handleCellClick(params),
            }}
          />
        </ThemeProvider>
      </>
    );
  }
}

export default withRouter(Nx_Table);
