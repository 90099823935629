import React from 'react';
import PropTypes from 'prop-types';
import './scss/controls.scss';
import TextField from '@mui/material/TextField';
import DataTypes from '../../data/DataTypes';

class NxCtrlTextInput extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.inputStyle = this.props.inputStyle ? this.props.inputStyle : { fontSize: this.props.fontSize };
      this.inputLabelStyle = this.props.inputLabelStyle
         ? this.props.inputLabelStyle
         : { fontSize: this.props.fontLabelSize };
   }

   onChange = (event) => {
      let value = event.target.value;
      if (!value) {
         this.props.onChange(null);
         return;
      }

      // if (this.props.type === DataTypes.number) {
      //    this.handleNumberOnChange(value);
      //    return;
      // }

      this.props.onChange(value);
   };

   // handleNumberOnChange = (value) => {
   //    // Allow clearing the input (empty value)
   //    if (value === '') {
   //       this.props.onChange(value); // Let the input remain empty
   //       return;
   //    }

   //    // Parse the value as an integer
   //    let newValue = parseInt(value, 10);

   //    // If parsing fails, return without clamping
   //    if (isNaN(newValue)) {
   //       this.props.onChange(value); // Handle non-numeric intermediate values
   //       return;
   //    }

   //    // Apply min and max constraints
   //    if (this.props.type === DataTypes.number) {
   //       if (this.props.fieldConfig?.hasOwnProperty('min') && newValue < this.props.fieldConfig.min) {
   //          newValue = this.props.fieldConfig.min;
   //       }

   //       if (this.props.fieldConfig?.hasOwnProperty('max') && newValue > this.props.fieldConfig.max) {
   //          newValue = this.props.fieldConfig.max;
   //       }
   //    }

   //    this.props.onChange(newValue.toString());
   // };

   renderHelperText = () => {
      if (this.props.fieldConfig && this.props.fieldConfig.helperText) {
         return this.props.fieldConfig.helperText;
      }
      if (this.props.helperText && this.props.helperText.length) {
         return this.props.helperText;
      } else {
         if (this.props.fieldConfig?.datatype === 'number') {
            return 'This field only accept numbers';
         }
         return '';
      }
   };

   render() {
      return (
         <TextField
            style={this.props.style}
            label={this.props.fieldConfig ? this.props.fieldConfig.label : this.props.label}
            value={this.props.value != null || this.props.value != undefined ? this.props.value : ''}
            placeholder={this.props.placeholder}
            variant={this.props.variant}
            disabled={this.props.fieldConfig ? this.props.fieldConfig.disabled : this.props.disabled}
            error={this.props.error}
            autoFocus={this.props.autoFocus}
            fullWidth={this.props.fullWidth}
            helperText={this.renderHelperText()}
            type={this.props.type}
            InputProps={{
               inputProps: {
                  min: this.props.fieldConfig ? this.props.fieldConfig.min : this.props.min,
                  max: this.props.fieldConfig ? this.props.fieldConfig.max : this.props.max
               }
            }}
            InputLabelProps={{ style: this.inputLabelStyle }}
            onKeyDown={this.props.onKeyDown}
            onChange={this.onChange}
            multiline={this.props.fieldConfig ? this.props.fieldConfig.multiline : this.props.multiline}
            rows={this.props.fieldConfig ? (this.props.fieldConfig.multiline ? 5 : null) : null}
            size={this.props.size}
            className={` nx_textfield ${this.props.fieldConfig?.multiline ? ' nx_textfield_multiline ' : ''} ${
               this.props.className ? this.props.className : ''
            } ${this.props.error ? ' nx_error ' : ''}`}
         />
      );
   }
}

NxCtrlTextInput.propTypes = {
   className: PropTypes.string,
   type: PropTypes.oneOf(['text', 'password', 'number', 'color', 'file']),
   disabled: PropTypes.bool,
   label: PropTypes.string,
   variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
   fullWidth: PropTypes.bool,
   autoFocus: PropTypes.bool,
   error: PropTypes.bool,
   helperText: PropTypes.string,
   fontSize: PropTypes.string,
   fontLabelSize: PropTypes.string,
   multiline: PropTypes.bool,
   size: PropTypes.oneOf(['medium', 'small', 'string'])
};

NxCtrlTextInput.defaultProps = {
   className: '',
   disabled: false,
   fullWidth: true,
   autoFocus: false,
   error: false,
   helperText: '',
   type: 'text',
   fontSize: '12px',
   fontLabelSize: '11px',
   multiline: false,
   size: 'small'
};

export default NxCtrlTextInput;
