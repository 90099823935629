import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add, Settings, DeleteOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import NxFormDialog from '../../../../nix_components/components/form_wrappers/NxFormDialog';
import NxConfirm from '../../../../nix_components/ui_components/alerts/NxConfirm';
import NxButtonMenu from '../../../../nix_components/ui_components/controls/NxButtonMenu';

export const ClientFlowCard = ({ client, addNewClient = false, refetchClients }) => {
   const [clientItem, setClientItem] = useState(false);
   const [deleteItem, setDeleteItem] = useState(null);

   const navigate = useNavigate();

   const renderEditForm = () => {
      const activeForm = {
         rows: [
            ['is_active', {}],
            ['name', {}]
         ]
      };
      return (
         <NxFormDialog
            title={'Client'}
            open={Boolean(clientItem)}
            editItem={clientItem}
            config={activeForm}
            table={'clients'}
            onDialogClose={() => setClientItem(null)}
            afterSave={refetchClients}
            onSave={handleSaveClient}
         />
      );
   };

   const handleSaveClient = async (dataItem, callback) => {
      if (dataItem.isNew) {
         dataItem.Save((error) => {
            if (error.hasOwnProperty('error')) {
               global.UF.handleError(error.error);
               return;
            }

            if (callback) {
               callback();
               refetchClients();
            }
         });
         return;
      }

      const clients = await global.UF.dataProvider.get_v2(`clients`, { id: dataItem.id });

      if (clients.length) {
         const dbClient = clients[0];

         dbClient.name = dataItem.name;
         dbClient.is_active = dataItem.is_active;

         dbClient.Save((error) => {
            if (error.hasOwnProperty('error')) {
               global.UF.handleError(error.error);
               return;
            }
            if (callback) {
               callback();
               refetchClients();
            }
         });
      }
   };

   const handleDeleteItem = async () => {
      const clients = await global.UF.dataProvider.get_v2(`clients`, { id: deleteItem.id });

      if (clients.length) {
         const dbClient = clients[0];

         dbClient.Delete(() => {
            refetchClients();
            setDeleteItem(null);
         });

         return;
      }

      global.UF.handleError(`Client with id ${deleteItem.id} not found`);
   };

   const renderDeleteForm = () => {
      return (
         <NxConfirm
            show={true}
            onClose={() => setDeleteItem(null)}
            text={
               'Are you sure you want to delete this client, all stores and projects associated with client will be deleted as well?'
            }
            onClick={handleDeleteItem}
         />
      );
   };

   const handleClientAddClick = () => {
      const dataItem = global.UF.dataProvider.datastructure['clients'].new();

      setClientItem(dataItem);
   };

   if (addNewClient) {
      return (
         <>
            {Boolean(clientItem) && renderEditForm()}
            <div className="projectFlowCard">
               <div className="projectFlowCardTitle">Add new client</div>
               <div className="projectFlowCardContentAddNew">
                  <IconButton onClick={handleClientAddClick}>
                     <Add style={{ fontSize: '72px' }} />
                  </IconButton>
               </div>
            </div>
         </>
      );
   }

   return (
      <>
         {Boolean(clientItem) && renderEditForm()}
         {Boolean(deleteItem) && renderDeleteForm()}
         <div className="projectFlowCard">
            <div className="projectFlowCardTitleWrapper">
               <div className="projectFlowCardTitle">{client.name}</div>
               <NxButtonMenu
                  menuItems={[
                     {
                        label: 'Edit Client',
                        onClick: () => setClientItem(client)
                     },
                     {
                        label: 'Delete Client',
                        onClick: () => setDeleteItem(client)
                     },
                     {
                        label: 'Products',
                        onClick: () => navigate(`/clients/${client.id}/products`)
                     }
                  ]}
               >
                  <Settings />
               </NxButtonMenu>
            </div>
            <div className="projectFlowCardContent" onClick={() => navigate(`/clients/${client.id}`)}>
               <div className="projectFlowCardContentRow">
                  <div className="projectFlowCardContentRowLabel">Active</div>
                  <div className="projectFlowCardContentRowValue">{client.is_active ? 'Yes' : 'No'}</div>
               </div>
               <div className="projectFlowCardContentRow">
                  <div className="projectFlowCardContentRowLabel">Stores Count</div>
                  <div className="projectFlowCardContentRowValue">{client.stores_count}</div>
               </div>
               <div className="projectFlowCardContentRow">
                  <div className="projectFlowCardContentRowLabel">Projects Count</div>
                  <div className="projectFlowCardContentRowValue">{client.projects_count}</div>
               </div>
            </div>
         </div>
      </>
   );
};
