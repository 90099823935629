import React, { Fragment } from "react";
import "./scss/nx_content.scss";
import NxFormDialog from "../form_wrappers/NxFormDialog";
import DeleteItemModal from "../modals/DeleteItemModal";
import NxGridFilters from "./NxGridFilters";
import Grid from "../../ui_components/layouts/Grid/Grid";
import NxIconButton from "../../ui_components/controls/NxIconButton";
import { CSVLink } from "react-csv";
import { Add, Sync, Delete, Edit } from "@mui/icons-material";
import withRouter from "../utils/withRouter";
import DataTypes from "../../data/DataTypes";
import NxButtonMenu from "../../ui_components/controls/NxButtonMenu";
import NxLoader from "../utils/NxLoader";

class NxContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null,
      deleteItem: null,
      exportData: [],
      filters: {},
      hasTitle: props.title && props.title.length,
    };
    if (props.ref) {
      props.ref = this;
    }

    if (props.getRef) {
      props.getRef(this);
    }
    this.fetchData = () => {};
    this.fetchReferences = () => {};
    this.csvLink = React.createRef();
    this.lastUpdate = new Date().getTime();
  }

  handleGridButtons = (props) => {
    if (props.buttons) {
      if (props.edit) {
        props.buttons.edit = (params) => {
          return (
            <NxIconButton
              tooltip={"Edit"}
              onClick={() => this.onEditItem(params.row)}
              key={"edit"}
            >
              <Edit />
            </NxIconButton>
          );
        };
      }
      if (props.delete) {
        props.buttons.delete = (params, callback) => {
          return (
            <NxIconButton
              tooltip={"Delete"}
              onClick={() => this.onDeleteItem(params.row, callback)}
              key={"delete"}
            >
              <Delete />
            </NxIconButton>
          );
        };
      }
    }
  };

  onAddNew = () => {
    if (this.props.onAdd) {
      const dataItem = this.props.onAdd();
      if (!dataItem) {
        return;
      }

      this.setState({ editItem: dataItem });
    } else {
      const newItem =
        global.UF.dataProvider.datastructure[this.props.table].new();
      if (this.props.beforeAddNew) {
        this.props.beforeAddNew(newItem, () => {
          this.setState({ editItem: newItem });
        });
      } else {
        this.setState({ editItem: newItem });
      }
    }
  };

  onEditItem = (editItem) => {
    if (this.props.onBeforeEdit) {
      this.props.onBeforeEdit(editItem);
    }
    this.setState({ editItem });
  };

  onDeleteItem = (deleteItem, callback) => {
    this.setState({ deleteItem });
    this.fetchData = callback;
  };

  deleteItem = async () => {
    const { deleteItem } = this.state;
    if (this.props.onDelete) {
      try {
        await this.props.onDelete(deleteItem, () => {
          this.setState({ deleteItem: null }, () => {
            this.fetchData();
          });
        });
      } catch (err) {
        global.UF.handleError(err);
        return;
      }
    } else {
      deleteItem.Delete(async () => {
        if (this.props.afterDelete) {
          try {
            await this.props.afterDelete();
          } catch (err) {
            global.UF.handleError(err);
            return;
          }
        }
        this.setState({ deleteItem: null }, () => {
          this.fetchData();
        });
      });
    }
  };

  onDialogClose = () => {
    this.lastUpdate = new Date().getTime();
    this.setState({ editItem: null });
  };

  getSyncFunction = (fetchData, filters) => {
    this.fetchData = fetchData;
    if (this.props.fetchData) {
      this.props.fetchData(fetchData, filters);
    }
    this.setState({ filters });
  };

  getReferenceFunction = (fetchReferences, filters) => {
    this.fetchReferences = fetchReferences;
    if (this.props.fetchReferences) {
      this.props.fetchReferences(fetchReferences, filters);
    }
  };

  exportData = async (extension) => {
    const filters = this.state.filters;
    const table = this.props.exportTable
      ? this.props.exportTable
      : this.props.table;
    if (typeof filters !== "object") {
      global.UF.handleError("The filters are not in right format");
      return;
    }

    if (this.props.exportTable && this.props.exportTable != this.props.table) {
      const ds = global.UF.dataProvider.datastructure[this.props.exportTable];
      Object.keys(filters).forEach((key) => {
        if (ds.fieldsArr.findIndex((field) => field.field === key) === -1) {
          delete filters[key];
        }
      });
    }

    try {
      global.loadingSetVisibility(true);

      let fields = [];
      if (global.UF.data_structure[table].exportFields) {
        fields = global.UF.data_structure[table].exportFields.map((field) => {
          const temp_field = global.UF.data_structure[table].fieldsArr.find(
            (f) => f.field == field
          );
          return temp_field;
        });
      } else {
        fields = global.UF.data_structure[table].fieldsArr;
        const exclude_fields = global.UF.dataProvider.datastructure[table]
          .exportExcludeFields
          ? global.UF.dataProvider.datastructure[table].exportExcludeFields
          : [];

        fields = fields.filter(
          (field) => !exclude_fields.includes(field.field)
        );
      }

      const references = {};
      const reference_fields = fields.filter(
        (field) => field.datatype == DataTypes.reference
      );

      for await (const reference_field of reference_fields) {
        if (!references[reference_field.reference]) {
          const items = await global.UF.dataProvider.referenceProvider.get_v2(
            reference_field.reference
          );

          references[reference_field.reference] = items;
        }
      }

      fields.forEach((field) => {
        if (field.datatype == DataTypes.reference) {
          field.items = references[field.reference];
        }
      });

      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/export`,
        {
          table: table,
          filters: filters,
          fields: fields,
          extension: extension,
        },
        true,
        true,
        true,
        extension == "xlsx" ? "buffer" : "json"
      );

      let blob = null;

      if (extension == "csv") {
        const csvString = response.body.toString("utf-8");
        blob = new Blob([csvString], { type: "text/csv" });
      } else {
        blob = response.body;
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      const file_name = this.props.exportFileName
        ? this.props.exportFileName
        : this.props.table;
      a.download = `${file_name}.${extension}`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      global.UF.setMaskVisibility(false);
    } catch (err) {
      global.UF.handleError(err.toString());
      console.error(err);
    }
  };

  renderTools = () => {
    return (
      <Grid
        style={{
          gridAutoFlow: "column",
          width: "auto",
          gap: "10px",
          marginRight: "10px",
        }}
      >
        {this.props.add ? (
          <Grid className="uf_content_wrapper_for_tools">
            <NxIconButton tooltip={"Add"} onClick={this.onAddNew}>
              <Add />
            </NxIconButton>
          </Grid>
        ) : null}
        {this.props.sync ? (
          <Grid className="uf_content_wrapper_for_tools">
            <NxIconButton tooltip={"Sync"} onClick={() => this.fetchData()}>
              <Sync />
            </NxIconButton>
          </Grid>
        ) : null}
        {this.props.export ? (
          <Grid className="uf_content_wrapper_for_tools">
            <NxButtonMenu
              variant="text"
              label="Export List"
              menuItems={[
                {
                  label: ".xlsx",
                  onClick: () => this.exportData("xlsx"),
                },
                {
                  label: ".csv",
                  onClick: () => this.exportData("csv"),
                },
              ]}
            />
          </Grid>
        ) : null}
        {this.renderAdditionalTools()}
      </Grid>
    );
  };

  renderAdditionalTools = () => {
    if (this.props.tools && Object.keys(this.props.tools).length) {
      return Object.keys(this.props.tools).map((tool) => {
        return (
          <Grid className={"uf_content_wrapper_for_tools"} key={tool}>
            {this.props.tools[tool]()}
          </Grid>
        );
      });
    } else {
      return null;
    }
  };

  componentDidMount = () => {
    this.handleGridButtons(this.props);
    this.forceUpdate();
  };

  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.router) != JSON.stringify(nextProps.router)) {
      this.handleGridButtons(nextProps);
    }
    return true;
  };

  /** MODALS **/

  renderEditForm = () => {
    const activeForm = this.state.editItem.isNew
      ? this.props.hasOwnProperty("formConfigAdd")
        ? this.props.formConfigAdd
        : this.props.formConfig
      : this.props.formConfig;
    return (
      <NxFormDialog
        fullTitle={this.props.fullTitle}
        title={this.props.title}
        open={this.state.editItem != null}
        helper={this.props.helper}
        editItem={this.state.editItem}
        config={activeForm}
        table={this.props.table}
        onBeforeDataItemSave={this.props.onBeforeDataItemSave}
        onDataItemsLoad={this.props.onDataItemsLoad}
        fileUpload={this.props.fileUpload}
        onSave={this.props.onSave}
        afterSave={this.props.afterSave}
        onDialogClose={this.onDialogClose}
      />
    );
  };

  renderDeleteModal = () => {
    return (
      <DeleteItemModal
        open={Boolean(this.state.deleteItem)}
        onClose={() => this.setState({ deleteItem: null })}
        onDelete={this.deleteItem}
        title={this.props.deleteItemTitle ? this.props.deleteItemTitle : null}
        text={
          this.props.deleteItemText
            ? this.props.deleteItemText
            : `Are you sure you want to delete this item?`
        }
      />
    );
  };

  renderContent = () => {
    return (
      <NxGridFilters
        id={this.props.id}
        table={this.props.table}
        renderTools={this.renderTools}
        permission={this.props.permission}
        additional_data={this.props.additional_data}
        onDataItemsLoad={this.props.onDataItemsLoad}
        lastUpdate={this.lastUpdate}
        buttons={this.props.buttons}
        customFiltering={this.props.customFiltering}
        popOverButtons={
          this.props.popOverButtons ? this.props.popOverButtons : {}
        }
        handleParentFetchData={this.props.handleParentFetchData}
        fetchData={this.fetchData}
        syncData={this.getSyncFunction}
        getReferenceFunction={this.getReferenceFunction}
        onEdit={this.onEditItem}
        onDelete={this.onDeleteItem}
        attendeeType={this.props.attendeeType}
        registrationType={this.props.registrationType}
        gridProps={this.props.gridProps}
        onRowClick={this.props.onRowClick}
        rowDoubleClick={this.props.rowDoubleClick}
        initialSearch={this.props.initialSearch}
        rowHeight={this.props.rowHeight}
        renderCustomFilters={this.props.renderCustomFilters}
        expandableRows={this.props.expandableRows}
        checkboxSelection={this.props.checkboxSelection}
        handleSelectionModelChange={this.props.handleSelectionModelChange}
        handleExpadableRowChange={this.props.handleExpadableRowChange}
        expandableRowData={this.props.expandableRowData}
        tableType={this.props.tableType}
        rows={this.props.rows}
        filters={this.props.filters}
        showTotals={this.props.showTotals}
        disableColumnMenu={this.props.disableColumnMenu}
        fullTitle={this.props.fullTitle}
        activeRows={this.props.activeRows}
      />
    );
  };

  /** END MODALS **/

  render() {
    if (this.props.hasOwnProperty("isLoaded") && !this.props.isLoaded) {
      return <NxLoader show={true} />;
    }
    return (
      <Fragment>
        {this.state.deleteItem && this.renderDeleteModal()}
        {this.state.editItem != null && this.renderEditForm()}
        <Grid className={"uf_content_wrapper"}>
          {this.state.hasTitle ? (
            <Grid className={"uf_content_upper"}>
              <Grid className={"uf_content_title"}>{this.props.title}</Grid>
            </Grid>
          ) : null}

          {this.renderContent()}
        </Grid>
      </Fragment>
    );
  }
}

export default withRouter(NxContent);
