import React, { useCallback, useEffect, useMemo, useState } from "react";
import NxLoader from "../../../../../../nix_components/components/utils/NxLoader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { TreeView, TreeItem } from "@mui/x-tree-view/";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Collapse,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
  Icon,
} from "@mui/material";
import moment from "moment";
import {
  DeleteOutline,
  ExpandMore,
  ChevronRight,
  AddOutlined,
  Search,
  ErrorOutline,
  EditOutlined,
} from "@mui/icons-material";
import NxConfirm from "../../../../../../nix_components/ui_components/alerts/NxConfirm";
import NxFormDialog from "../../../../../../nix_components/components/form_wrappers/NxFormDialog";
import NxButton from "../../../../../../nix_components/ui_components/controls/NxButton";
import NxDialog from "../../../../../../nix_components/ui_components/dialogs/NxDialog";
import { PrintLocators } from "./PrintLocators";
import DeleteItemModal from "../../../../../../nix_components/components/modals/DeleteItemModal";
import { DeactivateLocators } from "./DeactivateLocators";
import { AddLocators } from "./AddLocators";
import { ChooseLocators } from "./ChooseLocators";
import PrintIcon from "@mui/icons-material/Print";
import { PrintSessionItems } from "./PrintSessionItems";

export const DepartmantTree = ({ departmantId, idProject }) => {
  const [treeData, setTreeData] = useState(null);
  const [filterTreeData, setFilteredData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedTreeItems, setExpandedTreeItems] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [newItem, setNewItem] = useState(null);
  const [filters, setFilters] = useState({});
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showAddLocator, setShowLocator] = useState(false);
  const [showAddLocators, setShowAddLocators] = useState(false);
  const [printSessionItems, setPrintSessionItems] = useState(false);

  const [printLocators, setPrintLocators] = useState(false);
  const [editSessionItem, setEditSessionItemModal] = useState(false);
  const [deleteSessionItem, setDeleteSessionItemModal] = useState(false);
  const [temporaryTree, setTemporaryTree] = useState([]);
  const [sessionItems, setSessionItems] = useState(null);

  const handleExpandClick = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSearchChange = (event) => {
    setFilters({ ...filters, search: event.target.value?.toLowerCase() });
  };

  const handleToggleDuplicates = (event) => {
    setFilters({ ...filters, duplicates: event.target.checked });
  };

  const handleToggleErrors = (event) => {
    setFilters({ ...filters, errors: event.target.checked });
  };

  const handleToggleUnresolved = (event) => {
    setFilters({ ...filters, unresolved: event.target.checked });
  };

  const handleSetEditSessionItemModal = async (item) => {
    const response = await global.UF.dataProvider.get_v2(`session_items`, {
      id: item.id,
    });

    if (!response.length) {
      global.UF.handleError({ message: "Session item not found" });
      return;
    }

    setEditSessionItemModal(response[0]);
  };

  const handleSetDeleteSessionItemModal = async (item) => {
    const response = await global.UF.dataProvider.get_v2(`session_items`, {
      id: item.id,
    });

    if (!response.length) {
      global.UF.handleError({ message: "Session item not found" });
      return;
    }

    setDeleteSessionItemModal(response[0]);
  };

  const renderDeleteSessionItemModal = () => {
    const handleDeleteSessionItem = async () => {
      await deleteSessionItem.DeleteAsync();

      // const sessionItems = await fetchSessionItems(selectedItem.code);

      await handleRefetchSelectedLocator();

      // setSelectedItem((prev) => ({ ...prev, data: sessionItems }));

      setDeleteSessionItemModal(null);
    };

    return (
      <DeleteItemModal
        onClose={() => setDeleteSessionItemModal(null)}
        open={Boolean(deleteSessionItem)}
        onDelete={handleDeleteSessionItem}
        text="Are you sure you want to delete this session item?"
      />
    );
  };

  const renderEditSessionItemModal = () => {
    return (
      <NxFormDialog
        title={"Session Item"}
        open={Boolean(editSessionItem)}
        editItem={editSessionItem}
        config={{ rows: [["quantity", "code"]] }}
        table={"session_items"}
        onDialogClose={() => setEditSessionItemModal(null)}
        afterSave={handleAfterEditSessionItem}
      />
    );
  };

  const handleAfterEditSessionItem = async () => {
    const sessionItems = await fetchSessionItems(selectedItem.code);

    setSelectedItem((prev) => ({ ...prev, data: sessionItems }));
  };

  useEffect(() => {
    let filteredData = { ...treeData };

    if (Object.values(filters).every((filter) => !filter)) {
      setFilteredData(treeData);
    }

    if (filters.search) {
      filteredData.children = filteredData.children.filter((child) => {
        return `${child.code}`.toLowerCase().includes(filters.search);
      });
    }

    if (filters.duplicates) {
      filteredData.children = filteredData.children.filter(
        (child) => child.has_duplicates
      );
    }

    if (filters.errors) {
      filteredData.children = filteredData.children.filter(
        (child) => child.has_errors
      );
    }

    if (filters.unresolved) {
      filteredData.children = filteredData.children.filter(
        (child) => child.has_unresolved
      );
    }

    setFilteredData(filteredData);
  }, [filters]);

  const formConfig = useMemo(() => {
    return {
      rows: [["code", "quantity"]],
    };
  }, []);

  const handleAddItem = (sessionId) => {
    const newItem = global.UF.dataProvider.datastructure["session_items"].new();

    const sessionLocatorItem = selectedItem.data.find(
      (session) => session.id_session === sessionId
    );

    const locatorId = sessionLocatorItem.id;

    newItem.id_session = sessionId;
    newItem.id_locator = locatorId;

    setNewItem(newItem);
  };

  const fetchSessionItems = async (locator) => {
    const response = await global.UF.makeRequest_v2(
      `GET`,
      `/api/locator/${locator}/${departmantId}`
    );

    console.log(response.body, "responsee");

    return response.body;
  };

  const handleAfterSave = async () => {
    await handleRefetchSelectedLocator();

    setNewItem(null);
  };

  const renderAddDialog = () => {
    return (
      <NxFormDialog
        title={"Session Item"}
        open={Boolean(newItem)}
        editItem={newItem}
        config={formConfig}
        table={"session_items"}
        onDialogClose={() => setNewItem(null)}
        afterSave={handleAfterSave}
      />
    );
  };

  const handleSelectItem = async (event, nodeId) => {
    const findNode = (children) => {
      for (let node of children) {
        if (node.code === nodeId) return node;
        if (node.sublocators) {
          const found = findNode(node.sublocators);
          if (found) return found;
        }
      }

      return null;
    };

    if (nodeId === selectedItem?.code) return;

    const selectedNode = findNode(filterTreeData.children);

    selectedNode.data = await fetchSessionItems(nodeId);

    setSelectedItem(selectedNode);
  };

  const handleDelete = (sessionId, locator) => {
    setDeleteItem({ sessionId, locator });
  };

  const handleDeleteSessionLocator = async () => {
    const response = await global.UF.dataProvider.get_v2(`session_locators`, {
      id_session: deleteItem.sessionId,
      code: deleteItem.locator,
    });

    if (!response.length) {
      global.UF.handleError({ message: "Session not found" });
      return;
    }

    const deleteItemDb = response[0];

    deleteItemDb.Delete(async () => {
      selectedItem.data = selectedItem.data.filter(
        (session) => session.session.id !== deleteItem.sessionId
      );
      await handleRefetchSelectedLocator();
      setDeleteItem(null);
      global.UF.handleSuccess("Sesija za ovaj lokator je uspešno obrisana");
    });
  };

  const renderTree = (node) => {
    return (
      <TreeItem
        key={node.code}
        nodeId={node.code}
        sx={{
          padding: "10px",
          borderRadius: "8px",
          backgroundColor:
            selectedItem?.code === node.code
              ? "rgba(46, 95, 255, 0.7)"
              : "transparent",
          color:
            selectedItem?.code === node.code
              ? "white"
              : "rgba(46, 95, 255, 0.7)",
          "&.Mui-selected, &.Mui-selected.Mui-focused, & .MuiTreeItem-content.Mui-focused, & .MuiTreeItem-content:hover, & .MuiTreeItem-content.Mui-selected,":
            {
              color:
                selectedItem?.code === node.code
                  ? "white !important"
                  : "rgba(46, 95, 255, 0.7) !important",
            },
          "& .MuiTreeItem-content:hover": {
            backgroundColor: "transparent !important",
          },
        }}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "5px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (node?.sublocators?.length) {
                return;
              }

              handleSelectItem(event, node.code);
            }}
          >
            <Typography sx={{ fontWeight: "600" }}>
              {node.name || node.code}
            </Typography>
            {!node.has_errors &&
              !node.has_duplicates &&
              !node.has_unresolved &&
              "✅"}
            {(node.has_errors || node.has_duplicates) && (
              <ErrorOutline sx={{ color: "red", fontSize: 18 }} />
            )}
          </Box>
        }
      >
        {node.sublocators?.map((child) => renderTree(child))}
      </TreeItem>
    );
  };

  const DepartmentTreeViewFilters = () => (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{ width: "100%", paddingLeft: "20px" }}
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          borderRadius: "25px",
          padding: "5px 15px",
          transition: "0.3s",
          border: "1px solid #ddd",
          "&:hover": { border: "1px solid #aaa" },
          "&:focus-within": {
            border: "1px solid #007bff",
            backgroundColor: "#fff",
          },
        }}
      >
        <Search sx={{ color: "#555", marginRight: "10px", fontSize: 28 }} />
        <TextField
          fullWidth
          variant="standard"
          placeholder="Search Locators..."
          value={filters.search}
          onChange={handleSearchChange}
          InputProps={{
            disableUnderline: true,
            sx: { fontSize: 16 },
          }}
        />
      </Box>

      <Grid
        item
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Switch
              checked={filters.has_duplicates}
              onChange={handleToggleDuplicates}
              color="primary"
            />
          }
          label="Show Duplicates"
        />

        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Switch
              checked={filters.has_errors}
              onChange={handleToggleErrors}
              color="primary"
            />
          }
          label="Show Errors"
        />

        <FormControlLabel
          sx={{ userSelect: "none" }}
          control={
            <Switch
              checked={filters.has_unresolved}
              onChange={handleToggleUnresolved}
              color="primary"
            />
          }
          label="Show Unsresolved"
        />

        <IconButton onClick={() => setShowLocator(true)}>
          <AddOutlined />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderDeactivateLocatorsModal = () => {
    return (
      <DeactivateLocators
        open={showDeactivate}
        handleClose={() => setShowDeactivate(false)}
        treeData={treeData}
        handleFetchDepartmantTree={handleFetchDepartmantTree}
      />
    );
  };

  const renderConfirmLocators = () => {
    return (
      <ChooseLocators
        open={showAddLocators}
        treeData={treeData}
        setPrintLocators={() => setPrintLocators(true)}
        setTemporaryTree={setTemporaryTree}
        handleFetchDepartmantTree={handleFetchDepartmantTree}
        handleClose={() => setShowAddLocators(false)}
      />
    );
  };

  const renderAddLocatorModal = () => {
    return (
      <AddLocators
        departmantId={departmantId}
        open={showAddLocator}
        handleClose={() => setShowLocator(false)}
        treeData={treeData}
        handleFetchDepartmantTree={handleFetchDepartmantTree}
      />
    );
  };

  const renderDeleteConfirmModal = () => {
    return (
      <NxConfirm
        show={true}
        text={`Are you sure you want to delete this session for locator : ${deleteItem.locator}`}
        onClick={handleDeleteSessionLocator}
        onClose={() => setDeleteItem(null)}
      />
    );
  };

  const handleExportSessionByUser = async (id, session) => {
    console.log(departmantId);
    console.log(session.session.device_id);

    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/session_items_users/export`,
        {
          id_departmant: departmantId,
          idUser: session.session.device_id,
        },
        true,
        true,
        true,
        "buffer"
      );

      if (!response.ok) {
        global.UF.handleError(
          `Something went wrong while trying to export summary report`
        );
        console.error(response);
        return;
      }

      let blob = null;

      blob = response.body;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `SessionUser.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export summary report`
      );
      console.error(err);
      return;
    }
  };

  const handleRefetchSelectedLocator = useCallback(async () => {
    const response = await global.UF.makeRequest_v2(
      `GET`,
      `/api/locators-details/${selectedItem.code}`
    );

    if (!response.ok) {
      global.UF.handleError(response);
      return;
    }

    const sessionItems = await fetchSessionItems(selectedItem.code);

    response.body.data = sessionItems;

    setTreeData((prev) => {
      const newTreeData = { ...prev };
      const locatorIndex = newTreeData.children.findIndex(
        (locator) => locator.code === selectedItem.code
      );
      newTreeData.children[locatorIndex] = response.body;
      return newTreeData;
    });
    setFilteredData((prev) => {
      const newFilteredData = { ...prev };
      const locatorIndex = newFilteredData.children.findIndex(
        (locator) => locator.code === selectedItem.code
      );
      newFilteredData.children[locatorIndex] = response.body;
      return newFilteredData;
    });
    setSelectedItem(response.body);
  }, [selectedItem]);

  const handleFetchDepartmantTree = useCallback(async () => {
    if (!departmantId) return;

    try {
      if (isLoaded) setIsLoaded(false);

      const response = await global.UF.makeRequest_v2(
        `GET`,
        `/api/departmant/${departmantId}`
      );

      response.body.children.forEach((child) => {
        child.print = 1;
      });

      setTreeData(response.body);
      console.log(treeData, "treData");
      setFilteredData(response.body);
      setExpandedTreeItems([response.body.id.toString()]);
      setIsLoaded(true);
    } catch (err) {
      global.UF.handleError(err);
    }
  }, [departmantId]);

  useEffect(() => {
    handleFetchDepartmantTree();
  }, [departmantId]);

  if (!isLoaded) {
    return <NxLoader show={true} />;
  }

  if (printLocators) {
    return (
      <PrintLocators
        locatorCodes={temporaryTree.children.map((locator) => ({
          code: locator.code,
          name: treeData.name,
          print: locator.print,
        }))}
        onPrintComplete={() => setPrintLocators(false)}
      />
    );
  }

  const fetchForPrint = async (locator) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `/api/locator_print/${locator}/${idProject}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response) {
        throw new Error("Response is undefined");
      }

      if (!response.ok) {
        throw new Error(
          `Failed to fetch PDF: ${response.status} ${response.statusText}`
        );
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.download = "session_items.pdf";
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error in fetchForPrint:", error);
    }
  };

  return (
    <>
      {deleteItem && renderDeleteConfirmModal()}
      {newItem && renderAddDialog()}
      {showDeactivate && renderDeactivateLocatorsModal()}
      {showAddLocator && renderAddLocatorModal()}
      {editSessionItem && renderEditSessionItemModal()}
      {deleteSessionItem && renderDeleteSessionItemModal()}
      {showAddLocators && renderConfirmLocators()}
      <Grid
        sx={{
          gridTemplateRows: "10% 90%",
          height: "100%",
          gap: "15px",
          display: "grid",
        }}
      >
        <Grid
          sx={{
            gridTemplateColumns: "80% 10% 10%",
            display: "grid",
            alignItems: "center",
          }}
        >
          {DepartmentTreeViewFilters()}
          <NxButton
            label={"Deactivate Locators"}
            onClick={() => setShowDeactivate(true)}
          />
          <NxButton
            label={"Print Locators"}
            onClick={() => setShowAddLocators(true)}
          />
        </Grid>
        <Grid
          container
          spacing={3}
          height={"100%"}
          sx={{ gridTemplateColumns: "30% 68%", gap: "15px", display: "grid" }}
        >
          <Grid
            sx={{
              maxHeight: "650px",
              overflowY: "auto",
              padding: "10px",
              borderRadius: "8px",
              height: "100%",
              width: "100%",
            }}
          >
            <TreeView
              defaultCollapseIcon={
                <ExpandMore sx={{ color: "rgb(63, 102, 251)" }} />
              }
              defaultExpandIcon={
                <ChevronRight sx={{ color: "rgb(63, 102, 251)" }} />
              }
              onNodeToggle={(event, nodeIds) => setExpandedTreeItems(nodeIds)}
              expanded={expandedTreeItems}
              sx={{ transition: "all 0.3s ease-in-out" }}
            >
              <TreeItem
                key={filterTreeData.id}
                nodeId={String(filterTreeData.id)}
                sx={{
                  color: "rgba(46, 95, 255, 0.7)",
                  "&.Mui-selected, &.Mui-selected.Mui-focused, & .MuiTreeItem-content.Mui-focused, & .MuiTreeItem-content:hover, & .MuiTreeItem-content.Mui-selected,":
                    {
                      backgroundColor: "transparent !important",
                      color: "rgba(46, 95, 255, 0.7) !important",
                    },
                }}
                label={
                  <Typography sx={{ fontWeight: "600" }}>
                    {filterTreeData.name}
                  </Typography>
                }
              >
                {filterTreeData.children.map((child) => renderTree(child))}
              </TreeItem>
            </TreeView>
          </Grid>

          <Grid
            item
            sx={{
              borderRadius: "16px",
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              height: "100%",
              maxHeight: "680px",
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              {selectedItem ? (
                <Box
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    transition: "all 0.3s ease-in-out",
                    height: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "15px",
                        color: "rgba(46, 95, 255, 0.7)",
                        width: "95%",
                        justifySelf: "center",
                      }}
                    >
                      Locator: {selectedItem.code}
                    </Typography>
                    <PrintIcon
                      onClick={() => fetchForPrint(selectedItem.code)}
                    />
                  </div>
                  <Box
                    sx={{
                      overflowY: "scroll",
                      maxHeight: "600px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {selectedItem.data?.length === 0 && (
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "10px",
                          textAlign: "center",
                          color: "rgba(46, 95, 255, 0.7)",
                          placeSelf: "center",
                        }}
                      >
                        No sessions uploaded for this locator
                      </Typography>
                    )}
                    {selectedItem.data?.map((session, index) => (
                      <Card
                        key={index}
                        sx={{
                          marginBottom: "15px",
                          width: "95%",
                          borderRadius: "8px",
                          backgroundColor: "white",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          position: "relative",
                          transition: "height 3s ease-in-out",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "80% 20%",
                            padding: "10px",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <Box>
                            <IconButton
                              onClick={() =>
                                handleExpandClick(session.session.id)
                              }
                              sx={{ justifyContent: "start" }}
                            >
                              <ExpandMore
                                sx={{
                                  color: "gray",
                                  fontSize: 28,
                                  transition: "0.3s",
                                  transform: expanded[session.session.id]
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                              />
                            </IconButton>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                handleExportSessionByUser(
                                  session.session.id,
                                  session
                                )
                              }
                            >
                              <FileDownloadIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleAddItem(session.session.id)}
                              sx={{ justifyContent: "end" }}
                            >
                              <AddOutlined
                                sx={{
                                  fontSize: 28,
                                  transition: "0.3s",
                                  "&:hover": {
                                    color: "darkgreen",
                                    transform: "scale(1.1)",
                                  },
                                }}
                              />
                            </IconButton>

                            <IconButton
                              onClick={() =>
                                handleDelete(
                                  session.session.id,
                                  selectedItem.code
                                )
                              }
                              sx={{ justifyContent: "end" }}
                            >
                              <DeleteOutline
                                sx={{
                                  fontSize: 28,
                                  transition: "0.3s",
                                  "&:hover": {
                                    color: "darkred",
                                    transform: "scale(1.1)",
                                  },
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Box>

                        {/* Always Visible: Uploaded by & Date */}
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Uploaded by: {session.session.username}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "gray", marginBottom: "10px" }}
                          >
                            Uploaded on:{" "}
                            {session.session.end_date
                              ? moment(session.session.end_date).format("ll")
                              : "N/A"}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Total quantity: {session?.total_quantity ?? 0}
                          </Typography>
                        </CardContent>

                        {/* Conditionally Render Collapse to Adjust Card Height */}
                        {expanded[session.session.id] && (
                          <Collapse
                            in={expanded[session.session.id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <CardContent>
                              <Box
                                sx={{
                                  padding: "10px 0",
                                  backgroundColor: "white",
                                  borderRadius: "8px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Session Items:
                                </Typography>
                                {session.session_items.map((item, idx) => (
                                  <Card
                                    key={idx}
                                    sx={{
                                      marginBottom: "15px",
                                      padding: "10px 0",
                                      width: "100%",
                                      borderRadius: "8px",
                                      backgroundColor: "white",
                                      boxShadow:
                                        "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      paddingX: "15px",
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        sx={{
                                          color: item.errored
                                            ? "rgba(255, 46, 46, 0.7)"
                                            : "rgba(46, 95, 255, 0.7)",
                                        }}
                                      >
                                        {`Code : ${item.code}`}
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        sx={{
                                          color: item.errored
                                            ? "rgba(255, 46, 46, 0.7)"
                                            : "rgba(46, 95, 255, 0.7)",
                                        }}
                                      >
                                        {`Quantity : ${item.quantity}`}
                                      </Typography>
                                    </div>
                                    <div>
                                      <IconButton
                                        onClick={() =>
                                          handleSetEditSessionItemModal(
                                            item,
                                            session
                                          )
                                        }
                                        sx={{ color: "rgba(46, 95, 255, 0.7)" }}
                                      >
                                        <EditOutlined />
                                      </IconButton>
                                      <IconButton
                                        onClick={() =>
                                          handleSetDeleteSessionItemModal(item)
                                        }
                                        sx={{ color: "rgba(255, 46, 46, 0.7)" }}
                                      >
                                        <DeleteOutline />
                                      </IconButton>
                                    </div>
                                  </Card>
                                ))}
                              </Box>
                            </CardContent>
                          </Collapse>
                        )}
                      </Card>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: "center",
                    color: "rgba(46, 95, 255, 0.7)",
                  }}
                >
                  Please select a locator to view details
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
