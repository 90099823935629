import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import "../layouts/scss/nx_grid.scss";

const NxLoader = ({ show }) => {
  if (!show) return null;

  return (
    <Backdrop open={show} className="loader" color="#fff">
      <CircularProgress color={"primary"} />
    </Backdrop>
  );
};

export default NxLoader;
