import React, { useEffect, useState } from 'react';
import { StoreFlowCard } from './components/StoreFlowCard';
import { useParams } from 'react-router-dom';

export const StoreFlow = () => {
   const [stores, setStores] = useState([]);
   const [isLoaded, setIsLoaded] = useState(false);
   const [clientId, setClientId] = useState(null);

   const params = useParams();

   const fetchStores = async () => {
      const idClient = clientId ? clientId : params.id;

      const response = await global.UF.dataProvider.get_v2(`view_stores`, { id_client: idClient });

      setStores(response);
      setIsLoaded(true);
   };

   useEffect(() => {
      if (params && params.id) {
         setClientId(params.id);
         fetchStores();
      } else {
         if (clientId) {
            setClientId(null);
         }
      }
   }, [params]);

   if (!isLoaded) {
      return null;
   }

   return (
      <div className="projectWrapper">
         <StoreFlowCard addNewStore={true} refetchStores={fetchStores} clientId={clientId} />
         {stores.map((store, key) => {
            return <StoreFlowCard key={key} store={store} refetchStores={fetchStores} clientId={clientId} />;
         })}
      </div>
   );
};
