import React, { Fragment } from 'react';
import Secure from './app/Screens/secure/Secure';
import Public from './app/Screens/public/Public';
import UF from './app/nix_components/utils/UF';
import DataProvider from './app/nix_components/data/DataProvider';
import NxLoader from './app/nix_components/components/utils/NxLoader';
import NxLoaderRequests from './app/nix_components/components/utils/NxLoaderRequests';
import NxSnackbar from './app/nix_components/ui_components/alerts/NxSnackbar';

class App extends React.Component {
   constructor(props) {
      super(props);
      global.UF = new UF();
      global.UF.dataProvider = new DataProvider();

      this.state = {
         authenticated:
            global.localStorage.getItem('token') !== undefined && global.localStorage.getItem('token') !== null,
         user: null,
         initializing: true
      };
   }

   login = (loginObj) => {
      try {
         let user = JSON.parse(loginObj);
         if (user.hasOwnProperty('access_token')) {
            localStorage.setItem('token', user.access_token);
         }
         if (user.hasOwnProperty('access_token') && user.hasOwnProperty('id') && user.hasOwnProperty('email')) {
            localStorage.setItem('user', loginObj);
            this.setState({ user: user, authenticated: true });
         }
      } catch {
         this.setState({ user: null, authenticated: false });
      }
   };

   logout = () => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href = '/';
   };

   setUser = () => {
      const user =
         localStorage.getItem('user') != null && typeof JSON.parse(localStorage.getItem('user')) === 'object'
            ? JSON.parse(localStorage.getItem('user'))
            : null;
      if (user != null) {
         if (user.hasOwnProperty('access_token') && user.hasOwnProperty('id') && user.hasOwnProperty('email')) {
            this.setState({ authenticated: true, user: user });
         } else {
            this.setState({ authenticated: false, user: null });
         }
      } else {
         this.setState({ authenticated: false, user: null });
      }
   };

   componentDidMount = async () => {
      const link = document.querySelector("link[rel*='icon']");
      if (link) {
         link.href = `../../assets/images/logo.png`;
      }
      try {
         this.setUser();
         document.title = 'TStock';
         this.setState({ initializing: false });
      } catch (err) {
         console.error(`Error : ${err}`);
      }
   };

   renderApp = () => {
      if (this.state.authenticated) {
         return <Secure user={this.state.user} />;
      } else {
         return <Public onLogin={this.login} />;
      }
   };

   render() {
      if (this.state.initializing) {
         return <NxLoader show={true} />;
      } else {
         return (
            <Fragment>
               <NxSnackbar />
               <NxLoader />
               <NxLoaderRequests />
               {this.renderApp()}
            </Fragment>
         );
      }
   }
}

export default App;
