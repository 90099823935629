import React, { useEffect, useState } from 'react';
import { ClientFlowCard } from './components/ClientFlowCard';

export const ClientFlow = () => {
   const [clients, setClients] = useState([]);
   const [isLoaded, setIsLoaded] = useState(false);

   const fetchClients = async () => {
      const response = await global.UF.dataProvider.get_v2(`view_clients`);

      setClients(response);
      setIsLoaded(true);
   };

   useEffect(() => {
      fetchClients();
   }, []);

   if (!isLoaded) {
      return null;
   }

   return (
      <div className="projectWrapper">
         <ClientFlowCard addNewClient={true} refetchClients={fetchClients} />
         {clients.map((client, key) => {
            return <ClientFlowCard key={key} client={client} refetchClients={fetchClients} />;
         })}
      </div>
   );
};
