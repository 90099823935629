import React, { Fragment } from 'react';
import { NxForm, NxFormRow, NxFormCell } from './controls/NxFormControls';
import NxCtrlGeneric from '../../ui_components/controls/NxCtrlGeneric';
import './nx_form.scss';
import * as superagent from 'superagent';
import NxDialog from '../../ui_components/dialogs/NxDialog';

class NxFormDialog extends React.Component {
   constructor(props) {
      super(props);
      this.dataConfigs = global.UF.data_structure[props.table];
      this.state = {
         showValidations: false,
         errorHandler: {
            error: false,
            errorMessage: '',
            type: 'error',
            errorTitle: 'Error'
         }
      };
   }

   buttons = [
      {
         label: 'Cancel',
         onClick: () => this.handleClose(),
         variant: 'text'
      },
      {
         label: this.props.editItem.isNew ? 'Add' : 'Save',
         onClick: async () => await this.onSave(),
         variant: 'text'
      }
   ];

   handleClose = () => {
      this.props.editItem.Reset();
      this.props.onDialogClose();
   };

   renderField = (field, col, index) => {
      let tempField = field.hasOwnProperty('length')
         ? this.dataConfigs.fields[field]
         : { ...this.dataConfigs.fields[field.field], ...field };
      return (
         <NxFormCell cols={col} key={index}>
            <NxCtrlGeneric
               dataItem={this.props.editItem}
               showValidation={this.state.showValidations}
               fieldConfig={tempField}
               onKeyDown={tempField.multiline ? () => {} : this.onKeyDown}
            />
         </NxFormCell>
      );
   };

   renderRow = (row, index) => {
      if (row && row.length) {
         return (
            <NxFormRow key={index}>{row.map((field, index) => this.renderField(field, row.length, index))}</NxFormRow>
         );
      }
      return null;
   };

   onKeyDown = async (event) => {
      if (event.keyCode == 13) {
         await this.onSave();
      }
   };

   onSave = async () => {
      if (this.props.onBeforeDataItemSave) {
         this.props.onBeforeDataItemSave(this.props.editItem);
      }
      if (Object.keys(this.props.editItem.GetChangedFields()).length) {
         const isValid = await this.props.editItem.Validate();
         if (isValid) {
            if (this.props.onSave) {
               try {
                  await this.props.onSave(this.props.editItem, async () => {
                     this.setState({ showValidations: false });
                     this.handleClose();
                  });
               } catch (err) {
                  console.error(err);
                  global.UF.setAlertVisibility(true, err.toString(), 'error');
               }
            } else {
               if (this.props.editItem.hasOwnProperty(`files`)) {
                  if (this.props.editItem.files.length) {
                     this.props.editItem.files.forEach((file, index) => {
                        let newFile = new File([file], `${file.newName}`);
                        superagent
                           .post('/api/saveFiles')
                           .attach('file', newFile)
                           .field('original_name', file.name)
                           .then((response) => {
                              if (index + 1 == this.props.editItem.files.length) {
                                 this.props.editItem.Save(async (data) => {
                                    if (data.hasOwnProperty('error')) {
                                       global.UF.setAlertVisibility(true, data.error, 'error');
                                       return;
                                    } else {
                                       this.setState({ showValidations: false });
                                       this.handleClose();
                                       if (this.props.afterSave) {
                                          await this.props.afterSave(data);
                                       }
                                    }
                                 });
                              }
                           })
                           .catch((error) => {
                              console.error(`error body json stringify ${JSON.stringify(error)}`);
                           });
                     });
                  } else {
                     this.props.editItem.Save(async (data) => {
                        if (data.hasOwnProperty('error')) {
                           global.UF.setAlertVisibility(true, data.error, 'error');
                           return;
                        } else {
                           this.setState({ showValidations: false });
                           this.handleClose();
                           if (this.props.afterSave) {
                              await this.props.afterSave(data);
                           }
                        }
                     });
                  }
               } else {
                  this.props.editItem.Save(async (data) => {
                     if (data.hasOwnProperty('error')) {
                        global.UF.setAlertVisibility(true, data.error, 'error');
                        return;
                     } else {
                        this.setState({ showValidations: false });
                        this.handleClose();
                        if (this.props.afterSave) {
                           await this.props.afterSave(data);
                        }
                     }
                  });
               }
            }
         } else {
            this.setState({ showValidations: true });
         }
      } else {
         global.UF.setAlertVisibility(true, `The item you want to save haven't changed`, 'warning');
      }
   };

   render() {
      return (
         <NxDialog
            title={`${this.props.editItem.isNew ? 'Add' : 'Update'} ${this.props.title}`}
            helper={this.props.helper}
            handleClose={this.handleClose}
            buttons={this.buttons}
            open={this.props.open}
         >
            <NxForm>{this.props.config.rows.map((row, index) => this.renderRow(row, index))}</NxForm>
         </NxDialog>
      );
   }
}

export default NxFormDialog;
