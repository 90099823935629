import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add, Settings, DeleteOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import NxFormDialog from '../../../../nix_components/components/form_wrappers/NxFormDialog';
import NxConfirm from '../../../../nix_components/ui_components/alerts/NxConfirm';

export const StoreFlowCard = ({ store, addNewStore = false, refetchStores, clientId }) => {
   const [storeItem, setStoreItem] = useState(false);
   const [deleteItem, setDeleteItem] = useState(null);

   const navigate = useNavigate();

   const renderEditForm = () => {
      const activeForm = {
         rows: [
            ['is_active', {}],
            ['name', {}]
         ]
      };
      return (
         <NxFormDialog
            title={'Store'}
            open={Boolean(storeItem)}
            editItem={storeItem}
            config={activeForm}
            table={'clients'}
            onDialogClose={() => setStoreItem(null)}
            afterSave={refetchStores}
            onSave={handleSaveStore}
         />
      );
   };

   const handleSaveStore = async (dataItem, callback) => {
      if (dataItem.isNew) {
         dataItem.Save((error) => {
            if (error.hasOwnProperty('error')) {
               global.UF.handleError(error.error);
               return;
            }

            if (callback) {
               callback();
               refetchStores();
            }
         });
         return;
      }

      const stores = await global.UF.dataProvider.get_v2(`stores`, { id: dataItem.id });

      if (stores.length) {
         const dbStore = stores[0];

         dbStore.name = dataItem.name;
         dbStore.is_active = dataItem.is_active;

         dbStore.Save((error) => {
            if (error.hasOwnProperty('error')) {
               global.UF.handleError(error.error);
               return;
            }
            if (callback) {
               callback();
               refetchStores();
            }
         });
      }
   };

   const handleDeleteItem = async () => {
      const stores = await global.UF.dataProvider.get_v2(`stores`, { id: deleteItem.id });

      if (stores.length) {
         const dbStore = stores[0];

         dbStore.Delete(() => {
            refetchStores();
            setDeleteItem(null);
         });

         return;
      }

      global.UF.handleError(`Store with id ${deleteItem.id} not found`);
   };

   const renderDeleteForm = () => {
      return (
         <NxConfirm
            show={true}
            onClose={() => setDeleteItem(null)}
            text={
               'Are you sure you want to delete this store, all projects associated with this store will be deleted as well?'
            }
            onClick={handleDeleteItem}
         />
      );
   };

   const handleStoreAddClick = () => {
      const dataItem = global.UF.dataProvider.datastructure['stores'].new();
      dataItem.id_client = clientId;

      setStoreItem(dataItem);
   };

   if (addNewStore) {
      return (
         <>
            {Boolean(storeItem) && renderEditForm()}
            {Boolean(deleteItem) && renderDeleteForm()}
            <div className="projectFlowCard">
               <div className="projectFlowCardTitle">Add new store</div>
               <div className="projectFlowCardContentAddNew">
                  <IconButton onClick={handleStoreAddClick}>
                     <Add style={{ fontSize: '72px' }} />
                  </IconButton>
               </div>
            </div>
         </>
      );
   }

   return (
      <>
         {Boolean(storeItem) && renderEditForm()}
         {Boolean(deleteItem) && renderDeleteForm()}
         <div className="projectFlowCard">
            <div className="projectFlowCardTitleWrapper">
               <IconButton className={'projectFlowSettings'} onClick={() => setStoreItem(store)}>
                  <Settings />
               </IconButton>
               <div className="projectFlowCardTitle">{store.name}</div>
               <IconButton className={'projectFlowDelete'} onClick={() => setDeleteItem(store)}>
                  <DeleteOutline />
               </IconButton>
            </div>
            <div className="projectFlowCardContent" onClick={() => navigate(`/clients/${clientId}/stores/${store.id}`)}>
               <div className="projectFlowCardContentRow">
                  <div className="projectFlowCardContentRowLabel">Client</div>
                  <div className="projectFlowCardContentRowValue">{store.client}</div>
               </div>
               <div className="projectFlowCardContentRow">
                  <div className="projectFlowCardContentRowLabel">Active</div>
                  <div className="projectFlowCardContentRowValue">{store.is_active ? 'Yes' : 'No'}</div>
               </div>
               <div className="projectFlowCardContentRow">
                  <div className="projectFlowCardContentRowLabel">Projects Count</div>
                  <div className="projectFlowCardContentRowValue">{store.projects_count}</div>
               </div>
            </div>
         </div>
      </>
   );
};
