const referenceTypes = {
   local: 'local',
   remote: 'remote'
};

export const referenceConfigs = {
   stores: {
      id: 'id',
      label: 'name',
      type: referenceTypes.remote,
      table: 'stores'
   },
   projects: {
      id: 'id',
      label: 'name',
      type: referenceTypes.remote,
      table: 'projects'
   },
   clients: {
      id: 'id',
      label: 'name',
      type: referenceTypes.remote,
      table: 'clients'
   },
   departmants: {
      id: 'id',
      label: 'name',
      type: referenceTypes.remote,
      table: 'departmants',
      additional_fields: ['prefix', 'id_project']
   }
};
