import React, { useEffect, useState } from "react";
import { ProjectFlowCard } from "./components/ProjectFlowCard";
import { useParams } from "react-router-dom";

export const ProjectFlow = () => {
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [idStore, setIdStore] = useState(null);

  const params = useParams();

  const fetchProjects = async () => {
    const idStoreParam = idStore ? idStore : params.idStore;
    const response = await global.UF.dataProvider.get_v2(`view_projects`, {
      id_store: idStoreParam,
    });

    console.log(response);

    setProjects(response);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (params && params.idStore) {
      setIdStore(params.idStore);
    } else {
      if (idStore) {
        setIdStore(null);
      }
    }
    fetchProjects();
  }, [params]);

  if (!isLoaded) {
    return null;
  }

  return (
    <div className="projectWrapper">
      <ProjectFlowCard
        addNewProject={true}
        refetchProjects={fetchProjects}
        idStore={idStore}
      />
      {projects.map((project, key) => {
        return (
          <ProjectFlowCard
            key={key}
            project={project}
            refetchProjects={fetchProjects}
            idStore={idStore}
          />
        );
      })}
    </div>
  );
};
