import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./scss/controls.scss";
import { Tooltip } from "@mui/material";

export default function NxButtonMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.ignore = true;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItems = () => {
    if (
      !props.menuItems ||
      !Array.isArray(props.menuItems) ||
      props.menuItems.length == 0
    ) {
      return null;
    }

    return props.menuItems.map((mi, key) => {
      if (!mi) {
        return null;
      }
      return (
        <MenuItem
          key={key}
          onClick={mi.hasOwnProperty("onClick") ? mi.onClick : () => {}}
          className={"filters_card"}
          style={{ textTransform: "unset" }}
        >
          {mi.hasOwnProperty("label") ? mi.label : mi}
        </MenuItem>
      );
    });
  };

  const el = (
    <>
      <Button
        disabled={props.disabled}
        href={props.href ? props.href : null}
        target={props.href ? "_blank" : null}
        variant={props.variant}
        size={props.size}
        onClick={handleClick}
        type={props.type}
        style={props.style}
        className={`${
          props.variant === "contained"
            ? "nx_button_contained"
            : "nx_button_outlined"
        } ${props.className}`}
      >
        {props.children ? props.children : <span>{props.label}</span>}
      </Button>
      <Menu
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            borderRadius: "10",
            background: "#FBFBFC",
            boxShadow:
              "5px 5px 10px 0px rgba(34, 42, 96, 0.20), -5px -5px 10px 0px rgba(34, 42, 96, 0.07)",
          },
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );

  return props.disabled ? (
    el
  ) : (
    <Tooltip
      title={props.tooltip ? props.tooltip : props.label ? props.label : ""}
    >
      {el}
    </Tooltip>
  );
}

NxButtonMenu.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
};

NxButtonMenu.defaultProps = {
  className: "",
  variant: "text",
  size: "medium",
};
