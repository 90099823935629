import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Add, Settings, DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import NxFormDialog from "../../../../nix_components/components/form_wrappers/NxFormDialog";
import NxConfirm from "../../../../nix_components/ui_components/alerts/NxConfirm";

export const ProjectFlowCard = ({
  project,
  addNewProject = false,
  refetchProjects,
  idStore,
  idClient,
}) => {
  const [projectItem, setProjectItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();

  const renderEditForm = () => {
    const activeForm = {
      rows: [
        ["is_active", {}],
        ["name", "prefix"],
      ],
    };
    return (
      <NxFormDialog
        title={"Project"}
        helper={
          "Setting this project as active, will deactivate all other projects"
        }
        open={Boolean(projectItem)}
        editItem={projectItem}
        config={activeForm}
        table={"projects"}
        onDialogClose={() => setProjectItem(null)}
        afterSave={refetchProjects}
        onSave={handleSaveProject}
      />
    );
  };

  const handleDeleteItem = async () => {
    const projects = await global.UF.dataProvider.get_v2(`projects`, {
      id: deleteItem.id,
    });

    if (projects.length) {
      const dbProject = projects[0];

      dbProject.Delete(() => {
        refetchProjects();
        setDeleteItem(null);
      });

      return;
    }

    global.UF.handleError(`Project with id ${deleteItem.id} not found`);
  };

  const renderDeleteForm = () => {
    return (
      <NxConfirm
        show={true}
        onClose={() => setDeleteItem(null)}
        text={"Are you sure you want to delete this project?"}
        onClick={handleDeleteItem}
      />
    );
  };

  const handleSaveProject = async (dataItem, callback) => {
    if (dataItem.isNew) {
      dataItem.Save(async (error) => {
        if (error && error.hasOwnProperty("error")) {
          global.UF.handleError(error.error);
          return;
        }

        if (dataItem.is_active) {
          const response = await global.UF.dataProvider.get_v2(`projects`, {
            is_active: 1,
            custom_filter: `id != '${error.id}'`,
          });

          if (response.length) {
            for await (const project of response) {
              const dbProject = project;

              dbProject.is_active = 0;

              dbProject.Save((error) => {
                if (error && error.hasOwnProperty("error")) {
                  global.UF.handleError(error.error);
                  return;
                }
              });
            }
          }
        }

        if (callback) {
          callback();
          refetchProjects();
        }
      });
      return;
    }

    const projects = await global.UF.dataProvider.get_v2(`projects`, {
      id: dataItem.id,
    });

    if (projects.length) {
      const dbProject = projects[0];

      dbProject.name = dataItem.name;
      dbProject.is_active = dataItem.is_active;

      dbProject.Save(async (error) => {
        if (error && error.hasOwnProperty("error")) {
          global.UF.handleError(error.error);
          return;
        }

        if (dataItem.is_active) {
          const response = await global.UF.dataProvider.get_v2(`projects`, {
            is_active: 1,
            custom_filter: `id != '${dataItem.id}'`,
          });

          if (response.length) {
            for await (const project of response) {
              const dbProject = project;

              dbProject.is_active = 0;

              dbProject.Save((error) => {
                if (error && error.hasOwnProperty("error")) {
                  global.UF.handleError(error.error);
                  return;
                }
              });
            }
          }
        }

        if (callback) {
          callback();
          refetchProjects();
        }
      });
    }
  };

  const handleProjectAddClick = () => {
    const dataItem = global.UF.dataProvider.datastructure["projects"].new();
    dataItem.id_store = idStore;

    setProjectItem(dataItem);
  };

  if (addNewProject) {
    return (
      <>
        {Boolean(projectItem) && renderEditForm()}
        <div className="projectFlowCard">
          <div className="projectFlowCardTitle">Add new project</div>
          <div className="projectFlowCardContentAddNew">
            <IconButton onClick={handleProjectAddClick}>
              <Add style={{ fontSize: "72px" }} />
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {Boolean(deleteItem) && renderDeleteForm()}
      {Boolean(projectItem) && renderEditForm()}
      <div className="projectFlowCard">
        <div className="projectFlowCardTitleWrapper">
          <IconButton
            className={"projectFlowSettings"}
            onClick={() => setProjectItem(project)}
          >
            <Settings />
          </IconButton>
          <div className="projectFlowCardTitle">{project.name}</div>
          <IconButton
            className={"projectFlowDelete"}
            onClick={() => setDeleteItem(project)}
          >
            <DeleteOutline />
          </IconButton>
        </div>
        <div
          className="projectFlowCardContent"
          onClick={() =>
            navigate(`${location.pathname}/projects/${project.id}/products`)
          }
        >
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Active</div>
            <div className="projectFlowCardContentRowValue">
              {project.is_active ? "Yes" : "No"}
            </div>
          </div>
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Prefix</div>
            <div className="projectFlowCardContentRowValue">
              {project.prefix}
            </div>
          </div>
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Client</div>
            <div className="projectFlowCardContentRowValue">
              {project.client}
            </div>
          </div>
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Store</div>
            <div className="projectFlowCardContentRowValue">
              {project.store}
            </div>
          </div>
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Locators</div>
            <div className="projectFlowCardContentRowValue">
              {global.UF.formatNumber(project.resolved_locators)} /{" "}
              {global.UF.formatNumber(project.total_locators)}
            </div>
          </div>
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Finished</div>
            <div className="projectFlowCardContentRowValue">
              {project.finished ? "Yes" : "No"}
            </div>
          </div>
          <div className="projectFlowCardContentRow">
            <div className="projectFlowCardContentRowLabel">Items</div>
            <div className="projectFlowCardContentRowValue">
              {project.total_items}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
