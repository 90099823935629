import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import Products from "./components/Products/Products";
import NxLoader from "../../../nix_components/components/utils/NxLoader";
import Departmants from "./components/Departmants/Departmants";

export const ProjectDetails = () => {
  const [project, setProject] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tabs, setTabs] = useState([]);

  const [selectedTab, setSelectedTab] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const pathname = useMemo(() => {
    return `/clients/${params.id}/stores/${params.idStore}/projects/${params.idProject}`;
  }, [params]);

  const fetchProject = useCallback(async () => {
    if (params?.idProject) {
      const response = await global.UF.dataProvider.get_v2(`projects`, {
        id: params?.idProject,
      });

      if (response.length) {
        setProject(response[0]);
        setTabs([
          // {
          //    id: 'products',
          //    label: 'Products',
          //    element: <Products idProject={response[0]?.id} />
          // },
          {
            id: "departmants",
            label: "Departmants",
            element: (
              <Departmants
                idProject={response[0]?.id}
                prefixProject={response[0]?.prefix}
              />
            ),
          },
        ]);
        setIsLoaded(true);
      }

      return;
    }

    setProject(null);
    setIsLoaded(true);
  }, [params?.idProject]);

  useEffect(() => {
    if (params && params.idProject) {
      if (params.idProject === project?.id) {
        return;
      }
      fetchProject();
    } else {
      navigate(`/clients/${params.id}/stores/${params.idStore}`);
    }
  }, [params?.idProject]);

  useEffect(() => {
    if (params && params.tab) {
      const tab = tabs.find((tab) => tab?.id === params.tab);

      if (tab) {
        setSelectedTab(tab);
        return;
      }

      setSelectedTab(tabs[0]);
    }
  }, [params?.tab, tabs]);

  if (!isLoaded) {
    return <NxLoader show={true} />;
  }

  return (
    <div className="projectDetailsWrapper">
      <Tabs
        value={selectedTab ? selectedTab?.id : tabs[0].id}
        onChange={(e) => e.preventDefault()}
      >
        {tabs.map((tab, key) => {
          return (
            <Tab
              key={key}
              label={tab.label}
              value={tab.id}
              onClick={() => navigate(`${pathname}/${tab?.id}`)}
            />
          );
        })}
      </Tabs>
      {selectedTab?.element}
    </div>
  );
};
