import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Grid from "../../../../../../nix_components/ui_components/layouts/Grid/Grid";
import NxDialog from "../../../../../../nix_components/ui_components/dialogs/NxDialog";
import NxCtrlSelect from "../../../../../../nix_components/ui_components/controls/NxCtrlSelect";
import NxButton from "../../../../../../nix_components/ui_components/controls/NxButton";
import NxCtrlCheckbox from "../../../../../../nix_components/ui_components/controls/NxCtrlCheckbox";

const steps = ["Select File", "Configure File"];

const delimiters = [
  { id: ",", label: "Comma - ," },
  { id: ";", label: "Semicolon - ;" },
  { id: "|", label: "Pipe - |" },
  { id: "\t", label: "Tab" },
];

export const ProductImportStepper = ({ open, handleClose, idClient }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [previewItems, setPreviewItems] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileHasHeader, setFileHasHeader] = useState(0);
  const [csvDelimiter, setCsvDelimiter] = useState(",");
  const [mapping, setMapping] = useState({
    SKU: "",
    CODE: "",
    DESCRIPTION: "",
    QUANTITY: "",
    PRICE: "",
    KEY: "",
  });
  const [loading, setLoading] = useState(false);

  const handleScanFiles = async () => {
    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/scan`,
      {},
      true,
      true,
      false
    );

    if (!response.ok) {
      global.UF.handleError(response);
      return;
    }

    const { body } = response;

    setFiles(body);
  };

  const handleGetPreview = async () => {
    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/preview`,
      { filename: selectedFile },
      true,
      true,
      false
    );

    if (!response.ok) {
      global.UF.handleError(response);
      return;
    }

    const { body } = response;

    setPreviewItems(body);
  };

  useEffect(() => {
    handleScanFiles();
  }, []);

  const handleNext = () => {
    if (isStepValid(activeStep)) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return selectedFile !== null;
      case 1:
        return csvDelimiter !== "" && mapping.CODE;
      default:
        return true;
    }
  };

  useEffect(() => {
    setMapping({
      SKU: "",
      CODE: "",
      DESCRIPTION: "",
      QUANTITY: "",
      PRICE: "",
      KEY: "",
    });
  }, [fileHasHeader, csvDelimiter]);

  useEffect(() => {
    if (activeStep === 0) {
      setPreviewItems([]);
      setMapping({
        SKU: "",
        CODE: "",
        DESCRIPTION: "",
        QUANTITY: "",
        PRICE: "",
        KEY: "",
      });
      return;
    }

    if (activeStep === 1) {
      handleGetPreview();
    }
  }, [activeStep]);

  const handleFinish = async () => {
    const formattedMapping = Object.entries(mapping)
      .filter(([key, value]) => value !== "") // Filter out empty string values
      .reduce((acc, [key, value]) => {
        acc[key] = value; // Rebuild the object with non-empty values
        return acc;
      }, {});

    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/upload`,
      {
        filename: selectedFile,
        delimiter: csvDelimiter,
        mapping: formattedMapping,
        fileHasHeader,
        idClient,
      },
      true,
      true,
      false
    );

    if (!response.ok) {
      const { body } = response;
      global.UF.handleError(body.error);
      return;
    }

    handleClose();
  };

  const buttons = [
    {
      label: "Back",
      disabled: activeStep === 0,
      onClick: handleBack,
    },
    {
      label: activeStep === 1 ? "Upload" : "Next",
      disabled: !isStepValid(activeStep),
      onClick: activeStep === 1 ? handleFinish : handleNext,
    },
  ];

  return (
    <NxDialog
      open={open}
      handleClose={handleClose}
      buttons={buttons}
      width={"80%"}
      height={"auto"}
    >
      <Grid
        style={{
          gridTemplateRows: "75px auto",
          margin: "10px 0",
          minHeight: "200px",
          gap: "15px",
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid
          style={{ alignItems: "center", justifyItems: "center", gap: "20px" }}
        >
          {activeStep === 0 && (
            <Grid
              style={{
                gridTemplateColumns: "80% 20%",
                width: "50%",
                justifyItems: "center",
                justifyContent: "center",
                height: "auto",
                alignItems: "center",
              }}
            >
              <NxCtrlSelect
                label={"Select file to use"}
                data={files}
                value={selectedFile}
                onChange={setSelectedFile}
              />
              <NxButton onClick={handleScanFiles} label={"Scan Files"} />
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid style={{ gridTemplateColumns: "30% 65%", gap: "40px" }}>
              <Grid
                style={{
                  alignItems: "center",
                  justifyItems: "start",
                  gridTemplateRows: "20px auto",
                  gap: "10px",
                }}
              >
                <Typography fontWeight={"bold"}>File Configuration:</Typography>
                <Grid style={{ gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                  <NxCtrlCheckbox
                    value={fileHasHeader}
                    onChange={setFileHasHeader}
                    label={"Remove file header"}
                  />

                  <NxCtrlSelect
                    data={delimiters}
                    value={csvDelimiter}
                    onChange={setCsvDelimiter}
                    label="Choose delimiter"
                  />
                </Grid>
                <Typography fontWeight={"bold"}>Map Fields:</Typography>
                <Grid
                  style={{
                    gridTemplateRows: "repeat(5, 1fr)",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {[
                    "CODE",
                    "SKU",
                    "QUANTITY",
                    "PRICE",
                    "DESCRIPTION",
                    "KEY",
                  ].map((field, index) => {
                    const availableOptions = previewItems[fileHasHeader ? 1 : 0]
                      ?.split(csvDelimiter)
                      .filter(
                        (option) =>
                          !Object.values(mapping).includes(option) ||
                          mapping[field] === option
                      );

                    return (
                      <Grid
                        key={field}
                        style={{
                          gridTemplateColumns: "120px auto",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontWeight={"bold"}>{field}:</Typography>
                        <NxCtrlSelect
                          data={availableOptions}
                          value={mapping[field]}
                          onChange={(value) =>
                            setMapping({ ...mapping, [field]: value })
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid style={{ gap: "10px" }}>
                <Typography fontWeight={"bold"}>Preview Items:</Typography>

                <TableContainer
                  component={Paper}
                  style={{
                    maxWidth: "100%",
                    minHeight: "325px",
                    overflow: "auto",
                    boxShadow: "unset",
                  }}
                >
                  <Table sx={{ height: "100%" }}>
                    <TableHead sx={{ minHeight: "60px" }}>
                      <TableRow>
                        {previewItems[fileHasHeader ? 1 : 0]
                          ?.split(csvDelimiter)
                          .map((col, idx) => {
                            const colName = Object.keys(mapping).find(
                              (key) => mapping[key] === col
                            );

                            return (
                              <TableCell
                                key={idx}
                                style={{
                                  border: "1px solid black",
                                  fontWeight: "bold",
                                  backgroundColor: "#f0f0f0",
                                  textAlign: "center",
                                }}
                              >
                                {colName ?? "N/A"}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {previewItems
                        .slice(fileHasHeader ? 1 : 0)
                        .map((item, index) => (
                          <TableRow key={index}>
                            {item.split(csvDelimiter).map((col, idx) => (
                              <TableCell
                                key={idx}
                                style={{
                                  border: "1px solid black",
                                  textAlign: "center",
                                }}
                              >
                                {col}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </NxDialog>
  );
};
