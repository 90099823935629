import React, { useMemo, useState } from 'react';
import NxDialog from '../../../../../../nix_components/ui_components/dialogs/NxDialog';
import NxCtrlTextInput from '../../../../../../nix_components/ui_components/controls/NxCtrlTextInput';
import Grid from '../../../../../../nix_components/ui_components/layouts/Grid/Grid';

export const AddDepartmant = ({ open, handleClose, idProject, prefixProject }) => {
   const [departmantName, setDepartmantName] = useState(null);
   const [departmantPrefix, setDepartmantPrefix] = useState(null);

   const [from, setFrom] = useState(null);
   const [to, setTo] = useState(null);

   const [sublocatorFrom, setSublocatorFrom] = useState(null);
   const [sublocatorTo, setSublocatorTo] = useState(null);

   const errors = useMemo(() => {
      const departmantNameError =
         !departmantName || departmantName.replaceAll(' ', '').length === 0 ? 'Departmant Name cannot be empty' : '';

      const departmantPrefixError = !/^\d{3}$/.test(departmantPrefix)
         ? 'Departmant Prefix must be exactly 3 digits (0-9)'
         : '';

      const fromError = from == null || from < 0 ? '"From" must be greater than or equal to 0' : '';

      const toError = to == null || to <= 0 ? '"To" must be greater than 0' : '';

      const sublocatorFromError =
         sublocatorFrom && !/^(0[1-9]|[1-9][0-9])$/.test(sublocatorFrom)
            ? '"Sublocator From" must be between 01 and 99 if populated'
            : '';

      const sublocatorToError =
         sublocatorTo && !/^(0[1-9]|[1-9][0-9])$/.test(sublocatorTo)
            ? '"Sublocator To" must be between 01 and 99 if populated'
            : '';

      return {
         departmantName: departmantNameError,
         departmantPrefix: departmantPrefixError,
         from: fromError,
         to: toError,
         sublocatorFrom: sublocatorFromError,
         sublocatorTo: sublocatorToError
      };
   }, [departmantName, departmantPrefix, from, to, sublocatorFrom, sublocatorTo]);

   const handleGeneratingLocators = async (errors) => {
      const isValid = !Object.values(errors).some((error) => Boolean(error));

      if (isValid) {
         try {
            const response = await global.UF.makeRequest_v2(`POST`, `/api/locators`, {
               idProject,
               prefixProject,
               departmantName,
               departmantPrefix,
               from,
               to,
               sublocatorFrom,
               sublocatorTo
            });

            if (response.ok) {
               if (handleClose) {
                  handleClose();
               }
            }
         } catch (err) {
            global.UF.handleError(err);
            return;
         }
      }
   };

   const buttons = useMemo(() => {
      return [
         {
            label: 'Cancel',
            onClick: handleClose
         },
         {
            label: 'Generate',
            disabled: Object.values(errors).some((error) => Boolean(error)),
            onClick: async () => await handleGeneratingLocators(errors)
         }
      ];
   }, [errors]);

   return (
      <NxDialog open={open} handleClose={handleClose} buttons={buttons} title={'Add Departmant'}>
         <Grid style={{ minHeight: '350px', marginTop: '30px', gridTemplateRows: '1fr 1fr 50px 1fr', gap: '20px' }}>
            <Grid style={{ gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
               <NxCtrlTextInput
                  error={Boolean(errors['departmantName'])}
                  fieldConfig={{
                     label: 'Departmant Name',
                     helperText: errors['departmantName']
                  }}
                  value={departmantName}
                  onChange={setDepartmantName}
               />
               <NxCtrlTextInput
                  error={Boolean(errors['departmantPrefix'])}
                  fieldConfig={{
                     label: 'Departmant Prefix',
                     helperText: errors['departmantPrefix']
                  }}
                  value={departmantPrefix}
                  onChange={setDepartmantPrefix}
               />
            </Grid>
            <Grid style={{ gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
               <NxCtrlTextInput
                  error={Boolean(errors['from'])}
                  fieldConfig={{
                     label: 'From',
                     min: 1,
                     type: 'number',
                     helperText: errors['from']
                  }}
                  value={from}
                  onChange={setFrom}
               />
               <NxCtrlTextInput
                  error={Boolean(errors['to'])}
                  fieldConfig={{
                     label: 'To',
                     min: 1,
                     type: 'number',
                     helperText: errors['to']
                  }}
                  value={to}
                  onChange={setTo}
               />
            </Grid>

            <div style={{ fontWeight: '600', fontSize: '20px', letterSpacing: '1px', textAlign: 'center' }}>
               Sublocators
            </div>

            <Grid style={{ gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
               <NxCtrlTextInput
                  error={Boolean(errors['sublocatorFrom'])}
                  fieldConfig={{
                     label: 'From',
                     min: 1,
                     type: 'number',
                     helperText: errors['sublocatorFrom']
                  }}
                  value={sublocatorFrom}
                  onChange={setSublocatorFrom}
               />
               <NxCtrlTextInput
                  error={Boolean(errors['sublocatorTo'])}
                  fieldConfig={{
                     label: 'To',
                     min: 1,
                     type: 'number',
                     helperText: errors['sublocatorTo']
                  }}
                  value={sublocatorTo}
                  onChange={setSublocatorTo}
               />
            </Grid>
         </Grid>
      </NxDialog>
   );
};
