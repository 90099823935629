import React, { useState } from 'react';
import NxDialog from '../../../../../../nix_components/ui_components/dialogs/NxDialog';
import { Grid } from '@mui/material';
import NxCtrlFileInput from '../../../../../../nix_components/ui_components/controls/NxCtrlFileInput';

export const CheckQuantitiesDialog = ({ open, handleClose }) => {
   const [file, setFile] = useState(null);

   const handleFileChange = (files) => {
      if (files && files.length > 0) {
         setFile(files[0]);
      }
   };

   const handleFileUpload = async () => {
      if (!file) {
         global.UF.handleError('Please select a file to upload');
         return;
      }

      const formData = new FormData();
      formData.append('file', file);

      const token = localStorage.getItem('token');

      try {
         const response = await fetch('/api/locators/update_quantities', {
            method: 'POST',
            body: formData,
            headers: {
               Authorization: `Bearer ${token}`
            }
         });

         if (!response.ok) {
            global.UF.handleError('Error uploading file');
            return;
         }

         global.UF.handleSuccess(`Quantities successfully updated`);
         if (handleClose) {
            handleClose();
         }
      } catch (error) {
         console.error('Error:', error);
         global.UF.handleError('Error processing file');
      }
   };

   const buttons = [
      {
         label: 'Cancel',
         onClick: handleClose
      },
      {
         label: 'Upload',
         onClick: handleFileUpload
      }
   ];

   return (
      <NxDialog open={open} handleClose={handleClose} title="Check Quantities" buttons={buttons}>
         <Grid sx={{ padding: '20px', display: 'grid', width: '50%', height: '100%' }}>
            <NxCtrlFileInput
               field={'temp'}
               onChange={handleFileChange}
               label={'Choose file'}
               accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel']}
               downloadFile={true}
               downloadFileTooltip={'Download Template'}
               downloadFilePath={'examples/quantities_template.xlsx'}
               downloadFileName={'quantities_template.xlsx'}
            />
         </Grid>
      </NxDialog>
   );
};
