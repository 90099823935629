import request from 'superagent';
import numeral from 'numeral';

class UF {
   constructor() {
      this.dataProvider = null;
   }

   makeRequest = (
      type,
      url,
      data,
      includeCredentials = true,
      success,
      errorcb,
      showLoading = true,
      showAlert = true
   ) => {
      let message = '';
      let req = null;
      switch (type) {
         case 'GET':
            req = request.get(url);
            break;
         case 'POST':
            req = request.post(url);
            message = 'Success';
            break;
         case 'PUT':
            req = request.put(url);
            message = 'Success';
            break;
         case 'DEL':
            req = request.del(url);
            message = 'Success';
            break;
         default:
      }

      if (includeCredentials) {
         req = req.withCredentials();
      }

      if (data) {
         if (type == 'GET') {
            req = req.query(data);
         } else {
            req = req.send(data);
         }
      }

      req = req.set('Access-Control-Allow-Origin', global.location.protocol + '//' + global.location.host);

      if (includeCredentials) {
         req = req.set('authorization', 'Bearer ' + localStorage.getItem('token'));
      }
      //req.type('application/json').then(success, error);
      req.type('application/json').then(
         (data) => {
            if (showLoading) {
               this.setMaskVisibility(false);
            }

            if (type !== 'GET' && showAlert && includeCredentials) {
               this.handleSuccess(message);
            }
            success(data);
         },
         (err) => {
            let error = err;
            if (err && err.response && err.response.body) {
               let body = err.response.body;
               body = typeof body == 'string' && body.includes('{') && body.includes('}') ? JSON.parse(body) : body;
               error = {
                  code: body.code,
                  error: body.error
               };
            }

            if (err.status === 401) {
               this.logout();
            }
            if (err) {
               this.setMaskVisibility(false);
               if (showAlert) {
                  this.handleError(error.error, error.code >= 500);
               }
            }
            errorcb(error);
         }
      );

      if (showLoading) {
         this.setMaskVisibility(true);
      }
   };

   makeRequest_v2 = (
      type,
      url,
      data,
      includeCredentials = true,
      showLoading = true,
      showAlert = true,
      dataType = 'json'
   ) => {
      return new Promise(async (resolve, reject) => {
         let message = '';
         let req = null;

         switch (type) {
            case 'GET':
               req = request.get(url);
               break;
            case 'POST':
               req = request.post(url);
               message = 'Success';
               break;
            case 'PUT':
               req = request.put(url);
               message = 'Success';
               break;
            case 'DEL':
               req = request.del(url);
               message = 'Success';
               break;
            default:
         }

         if (includeCredentials) {
            req = req.withCredentials();
         }

         if (data) {
            if (type == 'GET') {
               req = req.query(data);
            } else {
               req = req.send(data);
            }
         }

         req = req.set('Access-Control-Allow-Origin', global.location.protocol + '//' + global.location.host);

         if (includeCredentials) {
            req = req.set('authorization', 'Bearer ' + localStorage.getItem('token'));
         }

         if (showLoading) {
            this.setMaskVisibility(true);
         }

         try {
            let data = null;

            if (dataType == 'json') {
               data = await req.type('application/json');
            }

            if (dataType == 'buffer') {
               data = await req.responseType('blob');
            }

            if (showLoading) {
               this.setMaskVisibility(false);
            }
            if (type !== 'GET' && showAlert && includeCredentials) {
               this.handleSuccess(message);
            }
            resolve(data);
         } catch (err) {
            let error = err;
            if (err && err.response && err.response.body) {
               let body = err.response.body;
               if (typeof body == 'string' && body.includes('{') && body.includes('}')) {
                  const parsed = JSON.parse(body);
                  error = {
                     code: parsed.code,
                     error: parsed.error
                  };
               } else {
                  error = {
                     code: err.response.body.code,
                     error: err.response.body.error
                  };
               }
            }

            if (err) {
               this.setMaskVisibility(false);
            }

            if (showAlert) {
               this.handleError(error.error, error.code >= 500);
            }
            if (err.status === 401) {
               this.logout();
            }

            reject(error.error);
         }
      });
   };

   logout = () => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href = '/';
   };

   setAlertVisibility = (show, message, type) => {
      if (global.setAlertVisibility) {
         global.setAlertVisibility(show, message, type);
      }
   };

   setProgressiveLoaderVisiblity = async (show, table, id, onSuccess = () => {}, onError = () => {}, className) => {
      if (global.progressLoadingSetVisibility) {
         await global.progressLoadingSetVisibility(show, table, id, onSuccess, onError, className);
      }
   };

   setMaskVisibility = (visible) => {
      if (global.loadingSetVisibility) {
         global.loadingSetVisibility(visible);
      }
   };

   capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }

   generateHeaderName = (item) => {
      if (item.label.includes('_')) {
         return `${this.capitalizeFirstLetter(item.label.split('_')[0])} ${this.capitalizeFirstLetter(
            item.label.split('_')[1]
         )}`;
      } else {
         return this.capitalizeFirstLetter(item.label);
      }
   };

   handleSuccess = (message) => {
      this.setAlertVisibility(true, message, 'success');
   };

   handleInfo = (message) => {
      this.setAlertVisibility(true, message, 'info');
   };

   handleError = (err, critical = false) => {
      if (err instanceof Error) {
         console.error(err);
         this.setAlertVisibility(true, err.toString(), critical ? 'error' : 'warning');
         return;
      }

      err = err && typeof err == 'string' ? err.replaceAll('Error:', '') : err;

      this.setAlertVisibility(true, err, critical ? 'error' : 'warning');
      return;
   };

   generateUniqueId = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let uniqueId = '';

      for (let i = 0; i < length; i++) {
         uniqueId += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return uniqueId;
   };

   formatNumber = (number, decimals = 0) => {
      if (isNaN(number)) return null; // Ensure the input is a valid number
      const formatPattern = `0,0.${'0'.repeat(decimals)}`; // Generate the format pattern based on the decimals
      return numeral(number).format(formatPattern);
   };
}

export default UF;
