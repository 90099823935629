/* eslint-disable react/jsx-pascal-case */
import React, { Fragment } from "react";
import NxGrid from "./NxGrid";
import NxTable from "./NxTable";
import NxFilters from "../filters/NxFilters";
import "./scss/nx_grid.scss";
import Grid from "../../ui_components/layouts/Grid/Grid";

class Nx_Grid_Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      fields: this.props.gridProps?.fields,
      activeFilters: {},
      lastUpdate: 0,
    };
  }

  shouldComponentUpdate = (nextProps) => {
    if (
      this.props.gridProps.fields.length != nextProps.gridProps.fields.length
    ) {
      this.setState({ fields: nextProps.gridProps.fields });
    }
    return true;
  };

  render() {
    return (
      <Fragment>
        <Grid className="uf_content_main">
          <Grid
            style={{
              width: "100%",
              height: "auto",
              gridAutoFlow: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <NxFilters
              table={this.props.table}
              onFiltersChange={(activeFilters) =>
                this.setState({
                  activeFilters,
                  lastUpdate: new Date().getTime(),
                })
              }
              renderCustomFilters={this.props.renderCustomFilters}
              initialSearch={this.props.initialSearch}
            />
            <Grid>{this.props.renderTools()}</Grid>
          </Grid>
          {this.props.tableType === "table" ? (
            <NxTable
              rowHeight={this.props.rowHeight}
              table={this.props.table}
              additional_data={this.props.additional_data}
              filters={this.state.activeFilters}
              onDataItemsLoad={this.props.onDataItemsLoad}
              syncData={this.props.syncData}
              attendeeType={this.props.attendeeType}
              customFiltering={this.props.customFiltering}
              registrationType={this.props.registrationType}
              id={this.props.id}
              onEdit={this.props.onEdit}
              onDelete={this.props.onDelete}
              showTotals={this.props.showTotals}
              lastUpdate={
                this.props.lastUpdate > this.state.lastUpdate
                  ? this.props.lastUpdate
                  : this.state.lastUpdate
              }
              handleParentFetchData={this.props.handleParentFetchData}
              fetchData={this.props.fetchData}
              buttons={this.props.buttons}
              popOverButtons={this.props.popOverButtons}
              rowDoubleClick={this.props.rowDoubleClick}
              onRowClick={this.props.onRowClick}
              onAdd={this.props.onAdd}
              row={this.props.rows}
              fields={this.state.fields}
              expandableRows={this.props.expandableRows}
              checkboxSelection={this.props.checkboxSelection}
              handleSelectionModelChange={this.props.handleSelectionModelChange}
              handleExpadableRowChange={this.props.handleExpadableRowChange}
              expandableRowData={this.props.expandableRowData}
            />
          ) : (
            <NxGrid
              rowHeight={this.props.rowHeight}
              showTotals={this.props.showTotals}
              table={this.props.table}
              additional_data={this.props.additional_data}
              filters={this.state.activeFilters}
              onDataItemsLoad={this.props.onDataItemsLoad}
              syncData={this.props.syncData}
              attendeeType={this.props.attendeeType}
              customFiltering={this.props.customFiltering}
              registrationType={this.props.registrationType}
              id={this.props.id}
              onEdit={this.props.onEdit}
              onDelete={this.props.onDelete}
              lastUpdate={
                this.props.lastUpdate > this.state.lastUpdate
                  ? this.props.lastUpdate
                  : this.state.lastUpdate
              }
              fetchData={this.props.fetchData}
              buttons={this.props.buttons}
              popOverButtons={this.props.popOverButtons}
              rowDoubleClick={this.props.rowDoubleClick}
              onRowClick={this.props.onRowClick}
              onAdd={this.props.onAdd}
              fields={this.state.fields}
              expandableRows={this.props.expandableRows}
              checkboxSelection={this.props.checkboxSelection}
              handleSelectionModelChange={this.props.handleSelectionModelChange}
            />
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default Nx_Grid_Filters;
