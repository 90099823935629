import React, { useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";

const BarcodeLabel = ({ locatorCode, departmentName }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, locatorCode, {
        format: "CODE128",
        displayValue: false,
        width: 1,
        height: 30,
      });
    }
  }, [locatorCode]);

  return (
    <div className="barcode-container" style={{ textAlign: "center" }}>
      <div
        style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center" }}
      >
        {departmentName}
      </div>
      <svg ref={barcodeRef}></svg>
      <div
        style={{ fontSize: "13px", fontWeight: "bold", textAlign: "center" }}
      >
        {locatorCode}
      </div>
    </div>
  );
};

export const PrintLocators = ({
  locatorCodes,
  onPrintComplete,
  locatorPrint,
}) => {
  console.log(locatorCodes, "Kodovi");
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: "Locator Codes",
    onAfterPrint: () => {
      if (onPrintComplete) onPrintComplete();
    },
  });

  useEffect(() => {
    if (handlePrint) {
      handlePrint();
    }
  }, []);

  const ROWS_PER_PAGE = 10;
  const COLUMNS = 3;
  const BARCODES_PER_PAGE = ROWS_PER_PAGE * COLUMNS;

  const pages = [];
  for (let i = 0; i < locatorCodes.length; i += BARCODES_PER_PAGE) {
    console.log(locatorCodes[i], "print");
    if (locatorCodes[i].print === 1) {
      console.log("usao ovoliko puta", locatorCodes[i]);
    }

    pages.push(locatorCodes.slice(i, i + BARCODES_PER_PAGE));
    console.log(pages);
  }

  return (
    <div ref={printRef} className="print-container">
      {pages
        .filter((page) => page.some(({ print }) => print)) // ✅ Filter out pages that have no items with print: true
        .map((page, pageIndex) => (
          <div key={pageIndex} className="print-page">
            <div className="page">
              <div className="barcode-grid">
                {page
                  .filter(({ print }) => print) // Filter items that have print: true
                  .map(({ code, name }, index) => (
                    <BarcodeLabel
                      key={index}
                      locatorCode={code}
                      departmentName={name}
                    />
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
