import React, { useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";

const BarcodeLabel = ({ locatorCode, departmentName }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, locatorCode, {
        format: "CODE128",
        displayValue: false,
        width: 1,
        height: 30,
      });
    }
  }, [locatorCode]);

  return (
    <div className="barcode-container" style={{ textAlign: "center" }}>
      <div
        style={{ fontSize: "10px", fontWeight: "bold", textAlign: "center" }}
      >
        {departmentName}
      </div>
      <svg ref={barcodeRef}></svg>
      <div
        style={{ fontSize: "10px", fontWeight: "bold", textAlign: "center" }}
      >
        {locatorCode}
      </div>
    </div>
  );
};

export const PrintSessionItems = ({
  locatorCodes,
  onPrintComplete,
  sessionItems,
  locatorPrint,
  locatorCode,
}) => {
  console.log(locatorCodes, "Kodovi");
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: "Locator Codes",
    onAfterPrint: () => {
      if (onPrintComplete) onPrintComplete();
    },
  });

  useEffect(() => {
    if (handlePrint) {
      handlePrint();
    }
  }, []);

  const ROWS_PER_PAGE = 10;
  const COLUMNS = 3;
  const BARCODES_PER_PAGE = ROWS_PER_PAGE * COLUMNS;

  const pages = [];
  // for (let i = 0; i < locatorCodes.length; i += BARCODES_PER_PAGE) {
  //   console.log(locatorCodes[i], "print");
  //   if (locatorCodes[i].print === 1) {
  //     console.log("usao ovoliko puta", locatorCodes[i]);
  //   }

  //   pages.push(locatorCodes.slice(i, i + BARCODES_PER_PAGE));
  //   console.log(pages);
  // }

  return (
    <div ref={printRef} className="print-container">
      <p>Locator:{locatorCode}</p>
      {sessionItems.map((item) => {
        return (
          <div>
            {item.barcode}
            {item.total_qty}
          </div>
        );
      })}
    </div>
  );
};
