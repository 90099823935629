import React, { Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Grid from '../../nix_components/ui_components/layouts/Grid/Grid.js';
import NxLoader from '../../nix_components/components/utils/NxLoader.jsx';
import withRouter from '../../nix_components/components/utils/withRouter.js';
import { ProjectDetails } from './ProjectDetails/ProjectDetails.jsx';
import { NxBreadcrumbs } from '../../nix_components/components/utils/NxBreadcrumbs.jsx';
import { ClientFlow } from './ClientFlow/ClientFlow.jsx';
import { StoreFlow } from './StoresFlow/StoreFlow.jsx';
import { ProjectFlow } from './ProjectFlow/ProjectFlow.jsx';
import LogoutIcon from '@mui/icons-material/Logout';
import NxIconButton from '../../nix_components/ui_components/controls/NxIconButton.jsx';
import NxButtonMenu from '../../nix_components/ui_components/controls/NxButtonMenu.jsx';
import { Settings } from '@mui/icons-material';
import Users from './Users/Users.jsx';
import DeviceUsers from './DeviceUsers/DeviceUsers.jsx';
import Products from './ProjectDetails/components/Products/Products.jsx';

class Secure extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         showMenu: true,
         loaded: false,
         initializing: true
      };
   }

   renderRoutes = () => {
      return (
         <Routes>
            <Route path="*" element={<Navigate to="/clients" replace />} />
            <Route path="/clients" element={<ClientFlow />} />
            <Route path="/clients/:id/products" element={<Products />} />
            <Route path="/clients/:id" element={<StoreFlow />} />
            <Route path="/clients/:id/stores/:idStore" element={<ProjectFlow />} />
            <Route
               path="/clients/:id/stores/:idStore/projects/:idProject?/:tab?/:tabId?"
               element={<ProjectDetails />}
            />
            <Route path="/users" element={<Users />} />
            <Route path="/device-users" element={<DeviceUsers />} />
         </Routes>
      );
   };

   componentDidMount = async () => {
      this.setState({ loaded: true });
   };

   onLogOut = () => {
      localStorage.clear('token');
      localStorage.clear('user');
      window.location.href = '/';
   };

   handleMenu = () => {
      const showMenu = !this.state.showMenu;
      this.setState({ showMenu });
   };

   handleBack = () => {
      if (this.props.router.location.key !== 'default') {
         this.props.router.navigate(-1);
      }
   };

   renderElement = () => {
      return (
         <Grid
            fullScreen={true}
            style={{
               backgroundColor: '#F8FAFB',
               width: '100%',
               overflow: 'hidden',
               gridTemplateRows: '50px auto',
               gap: '15px',
               padding: '20px',
               boxSizing: 'border-box'
            }}
         >
            <Grid
               style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  justifyContent: 'space-between',
                  gap: '10px'
               }}
            >
               <NxBreadcrumbs router={this.props.router} />
               <Grid style={{ display: 'grid', gridAutoFlow: 'column' }}>
                  <NxButtonMenu
                     menuItems={[
                        { label: 'Users', onClick: () => this.props.router.navigate('/users') },
                        { label: 'Device Users', onClick: () => this.props.router.navigate('/device-users') },
                        { label: 'Clients', onClick: () => this.props.router.navigate('/clients') }
                     ]}
                  >
                     <Settings sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                  </NxButtonMenu>
                  <NxIconButton onClick={this.onLogOut}>
                     <LogoutIcon />
                  </NxIconButton>
               </Grid>
            </Grid>
            {this.renderRoutes()}
         </Grid>
      );
   };

   render() {
      if (!this.state.loaded) {
         return <NxLoader show={!this.state.loaded} />;
      } else {
         return <Fragment>{this.renderElement()}</Fragment>;
      }
   }
}

export default withRouter(Secure);
