import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const history = createBrowserHistory();
root.render(
  <HistoryRouter history={history}>
    <App />
  </HistoryRouter>
);
