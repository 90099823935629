import { validators } from './DataValidators';
import DataTypes from './DataTypes';
import { dividerClasses } from '@mui/material';

export const static_configs = {
   users: {
      ignoreValidation: ['id', 'created_at', 'password'],
      fields: {
         id: {
            hide: true
         },
         is_active: {
            field: 'is_active',
            label: 'Active',
            defaultValue: 1
         },
         email: {
            field: 'email',
            label: 'Email',
            validators: [validators.isNull, validators.isEmpty, validators.isNotEmail]
         },
         password: {
            field: 'password',
            label: 'Password'
         }
      },
      errorHandling: (requestType, error, dataItem) => {
         const errorMsg = error.error;
         if (errorMsg.includes('users.email_UNIQUE')) {
            return {
               code: error.code,
               error: 'User with that email already exists!'
            };
         }
         return error;
      }
   },
   device_users: {
      ignoreValidation: ['id', 'created_at', 'password'],
      fields: {
         id: {
            hide: true
         },
         is_active: {
            field: 'is_active',
            label: 'Active',
            defaultValue: 1
         },
         username: {
            field: 'username',
            label: 'Username',
            validators: [validators.isNull, validators.isEmpty]
         },
         password: {
            field: 'password',
            label: 'Password'
         }
      },
      errorHandling: (requestType, error, dataItem) => {
         const errorMsg = error.error;
         if (errorMsg.includes('users.username_UNIQUE')) {
            return {
               code: error.code,
               error: 'Device user with that username already exists!'
            };
         }
         return error;
      }
   },
   stores: {
      ignoreValidation: ['id', 'created_at'],
      sorting: [{ field: 'created_at', sort: 'desc' }],
      fields: {
         name: {
            field: 'name',
            label: 'Name'
         },
         is_active: {
            datatype: DataTypes.boolean,
            field: 'is_active',
            label: 'Active',
            defaultValue: 1
         }
      },
      errorHandling: (method, err, dataItem) => {
         if (err && err.hasOwnProperty('error')) {
            if (err.error.includes(`stores.unique`)) {
               return {
                  code: err.code,
                  error: 'Store with that name for that client already exists!'
               };
            }
         }

         return err;
      }
   },
   warehouses: {
      sorting: [{ field: 'created_at', sort: 'desc' }],
      fields: {
         name: {
            field: 'name',
            label: 'Name'
         },
         is_active: {
            datatype: DataTypes.boolean,
            field: 'is_active',
            label: 'Active',
            defaultValue: 1
         },
         id_store: {
            field: 'id_store',
            label: 'Store',
            datatype: DataTypes.reference,
            reference: 'stores'
         }
      }
   },
   locators: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         id: {
            hide: true
         },
         code: {
            field: 'code',
            label: 'Code'
         },
         id_project: {
            field: 'id_project',
            label: 'Project',
            datatype: DataTypes.reference,
            reference: 'projects',
            filterable: true
         }
      }
   },
   projects: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         id: {
            hide: true
         },
         name: {
            field: 'name',
            label: 'Name'
         },
         prefix: {
            field: 'prefix',
            label: 'Prefix',
            datatype: DataTypes.number,
            validators: [validators.isNull, validators.isThreeDigits]
         },
         is_active: {
            field: 'is_active',
            label: 'Active'
         },
         id_store: {
            field: 'id_store',
            label: 'Store',
            datatype: DataTypes.autocomplete,
            reference: 'stores',
            addable: true,
            addable_field: 'name'
         },
         id_client: {
            field: 'id_client',
            label: 'Client',
            datatype: DataTypes.autocomplete,
            reference: 'clients',
            addable: true,
            addable_field: 'name'
         }
      },
      errorHandling: (method, err, dataItem) => {
         if (err && err.hasOwnProperty('error')) {
            if (err.error.includes(`projects.unique`)) {
               return {
                  code: err.code,
                  error: 'Project with that prefix for that store already exists!'
               };
            }
         }

         return err;
      }
   },
   view_projects: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         id: {
            hide: true
         },
         name: {
            field: 'name',
            label: 'Name'
         },
         prefix: {
            field: 'prefix',
            label: 'Prefix',
            datatype: DataTypes.number,
            validators: [validators.isNull, validators.isThreeDigits]
         },
         is_active: {
            field: 'is_active',
            label: 'Active',
            defaultValue: 1
         },
         id_store: {
            field: 'id_store',
            label: 'Store',
            datatype: DataTypes.autocomplete,
            reference: 'stores',
            addable: true,
            addable_field: 'name'
         },
         id_client: {
            field: 'id_client',
            label: 'Client',
            datatype: DataTypes.autocomplete,
            reference: 'clients',
            addable: true,
            addable_field: 'name'
         }
      }
   },
   departmants: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         id: {
            hide: true
         },
         name: {
            field: 'name',
            label: 'Name'
         },
         is_active: {
            field: 'is_active',
            label: 'Active',
            defaultValue: 1,
            filterable: true
         },
         id_project: {
            field: 'id_project',
            label: 'Project',
            datatype: DataTypes.select,
            reference: 'projects'
         }
      }
   },
   view_departmants: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         id: {
            hide: true
         },
         name: {
            field: 'name',
            label: 'Name'
         },
         is_active: {
            field: 'is_active',
            label: 'Active',
            defaultValue: 1,
            filterable: true
         },
         id_project: {
            field: 'id_project',
            label: 'Project',
            datatype: DataTypes.select,
            reference: 'projects'
         },
         errored_items_count: {
            field: 'errored_items_count',
            label: 'Errored Items Count',
            renderCell: ({ value }) => {
               return <div style={{ color: value > 0 ? 'red' : 'green' }}>{value}</div>;
            }
         },
         duplicate_locators_count: {
            field: 'duplicate_locators_count',
            label: 'Duplicate Locators Sessions Count',
            renderCell: ({ value }) => {
               return <div style={{ color: value > 0 ? 'red' : 'green' }}>{value}</div>;
            }
         },
         unresolved_locators_count: {
            field: 'unresolved_locators_count',
            label: 'Unresolved Locators Count',
            renderCell: ({ value }) => {
               return <div style={{ color: value > 0 ? 'red' : 'green' }}>{value}</div>;
            }
         }
      }
   },
   items: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         id: {
            hide: true
         },
         sku: {
            field: 'sku',
            label: 'SKU'
            // validators: [validators.isNull, validators.isEmpty]
         },
         id_project: {
            field: 'id_project',
            label: 'Project',
            datatype: DataTypes.reference,
            reference: 'projects'
         },
         quantity: {
            field: 'quantity',
            label: 'Quantity',
            datatype: DataTypes.number,
            validators: [validators.isNumber],
            defaultValue: 1
         },
         code: {
            field: 'code',
            label: 'Code',
            validators: [validators.isNull, validators.isEmpty, validators.isNumber]
         },
         description: {
            field: 'description',
            label: 'Description'
         },
         price: {
            field: 'price',
            label: 'Price',
            datatype: DataTypes.number,
            validators: [validators.isNumber]
         }
      },
      errorHandling: (method, err, dataItem) => {
         if (err && err.hasOwnProperty('error')) {
            if (err.error.includes(`items.unique_index`)) {
               return {
                  code: err.code,
                  error: 'Item with that code for that project already exists!'
               };
            }
         }

         return err;
      }
   },
   clients: {
      ignoreValidation: ['id', 'created_at'],
      fields: {
         is_active: {
            field: 'is_active',
            label: 'Active',
            defaultValue: 1
         }
      },
      errorHandling: (method, err, dataItem) => {
         if (err && err.hasOwnProperty('error')) {
            if (err.error.includes(`clients.name_UNIQUE`)) {
               return {
                  code: err.code,
                  error: 'Client with that name already exists!'
               };
            }
         }

         return err;
      }
   },
   session_items: {
      ignoreValidation: ['id', 'created_at'],
      fields: {}
   }
};
