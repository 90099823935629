import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const NxSnackbar = () => {
   const [show, setShow] = useState(false);
   const [message, setMessage] = useState('');
   const [type, setType] = useState('success');

   global.setAlertVisibility = (show, message, type) => {
      setShow(show);
      setMessage(message);
      setType(type);
   };

   return (
      <Snackbar
         open={show}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         autoHideDuration={3000}
         onClose={() => setShow(false)}
      >
         <Alert onClose={() => setShow(false)} variant="filled" severity={type} sx={{ width: '100%' }}>
            {message}
         </Alert>
      </Snackbar>
   );
};

export default NxSnackbar;
