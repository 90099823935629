import React, { useState, useMemo, useCallback, useEffect } from "react";
import NxDialog from "../../../../../../nix_components/ui_components/dialogs/NxDialog";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import NxButton from "../../../../../../nix_components/ui_components/controls/NxButton";
import { FixedSizeList as List } from "react-window";
import { PrintLocators } from "./PrintLocators";

export const ChooseLocators = ({
  open,
  handleClose,
  treeData,
  setTemporaryTree,
  setPrintLocators,
  handleFetchDepartmantTree,
}) => {
  const [locators, setLocators] = useState(() => {
    return treeData.children.map((locator) => ({
      id: locator.id,
      code: locator.code,
      print: locator.print,
      //   has_error: locator.has_errors,
      //   has_duplicates: locator.has_duplicates,
      //   has_unresolved: locator.has_unresolved,
    }));
  });

  useEffect(() => {
    setTemporaryTree(treeData);
  }, []);

  const [startRange, setStartRange] = useState("");
  const [endRange, setEndRange] = useState("");

  const handleDeactivate = useCallback(async () => {
    setLocators((prevLocators) => {
      const updatedLocators = prevLocators.map((locator) => ({
        id: locator.id,
        print: locator.print,
      }));

      console.log(updatedLocators);

      setTemporaryTree((prevTreeData) => {
        if (!prevTreeData || !Array.isArray(prevTreeData.children)) {
          console.error("prevTreeData.children is not an array:", prevTreeData);
          return prevTreeData;
        }

        return {
          ...prevTreeData,
          children: prevTreeData.children.map((node) => {
            const updatedLocator = updatedLocators.find(
              (loc) => loc.id === node.id
            );

            if (updatedLocator && updatedLocator.print !== node.print) {
              return { ...node, print: updatedLocator.print };
            }

            return node;
          }),
        };
      });

      return prevLocators;
    });
    setPrintLocators(true);
    handleClose();
  }, []);
  const buttons = useMemo(() => {
    return [
      {
        label: "Cancel",
        onClick: handleClose,
      },
      {
        label: "Print Locators",
        onClick: handleDeactivate,
      },
    ];
  }, []);

  const handleDeactivateChange = (e, locatorCode) => {
    setLocators((prev) =>
      prev.map((item) =>
        item.code === locatorCode
          ? { ...item, print: e.target.checked ? 1 : 0 }
          : item
      )
    );
  };

  const handleBulkDeactivate = () => {
    if (!startRange || !endRange) {
      global.UF.handleError(
        "Please enter a valid range in the correct format."
      );
      return;
    }

    // Ensure input follows the locator format
    const locatorPattern = /^\d{2}-\d{3}-\d{3}-\d{3}-\d{2}$/;
    if (!locatorPattern.test(startRange) || !locatorPattern.test(endRange)) {
      global.UF.handleError("Invalid format! Use: ??-???-???-???-??");
      return;
    }

    const startNumbers = startRange.match(/\d+/g);
    const endNumbers = endRange.match(/\d+/g);

    if (
      !startNumbers ||
      !endNumbers ||
      startNumbers.length < 5 ||
      endNumbers.length < 5
    ) {
      global.UF.handleError("Invalid range values.");
      return;
    }

    // Extract relevant segments
    const prefixStart = startNumbers.slice(0, 3).join("-"); // First three parts (00-123-002)
    const prefixEnd = endNumbers.slice(0, 3).join("-"); // First three parts of end

    const firstNumStart = parseInt(startNumbers[3], 10); // Fourth segment (e.g., 001)
    const secondNumStart = parseInt(startNumbers[4], 10); // Fifth segment (e.g., 00)

    const firstNumEnd = parseInt(endNumbers[3], 10); // Fourth segment (e.g., 002)
    const secondNumEnd = parseInt(endNumbers[4], 10); // Fifth segment (e.g., 00)

    if (
      prefixStart !== prefixEnd ||
      isNaN(firstNumStart) ||
      isNaN(secondNumStart) ||
      isNaN(firstNumEnd) ||
      isNaN(secondNumEnd)
    ) {
      global.UF.handleError("Invalid range values. Ensure the prefix matches.");
      return;
    }

    if (
      firstNumStart > firstNumEnd ||
      (firstNumStart === firstNumEnd && secondNumStart > secondNumEnd)
    ) {
      global.UF.handleError(
        "Start range must be less than or equal to the end range."
      );
      return;
    }

    setLocators((prev) =>
      prev.map((item) => {
        const numbers = item.code.match(/\d+/g);

        if (numbers && numbers.length >= 5) {
          const prefixItem = numbers.slice(0, 3).join("-"); // First three segments of item
          const firstNum = parseInt(numbers[3], 10);
          const secondNum = parseInt(numbers[4], 10);

          if (
            prefixItem === prefixStart && // Ensure prefix matches
            (firstNum > firstNumStart ||
              (firstNum === firstNumStart && secondNum >= secondNumStart)) &&
            (firstNum < firstNumEnd ||
              (firstNum === firstNumEnd && secondNum <= secondNumEnd))
          ) {
            return { ...item, print: 0 };
          }
        }

        return item;
      })
    );
  };

  // Virtualized Row Component
  const Row = ({ index, style }) => {
    const locator = locators[index];
    return (
      <Grid container spacing={2} style={style} alignItems="center">
        <Grid item xs={6}>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            color={"rgba(46, 95, 255, 0.7)"}
          >
            {locator.code}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(locator.print)}
                onChange={(e) => handleDeactivateChange(e, locator.code)}
                color="primary"
              />
            }
            label="Print"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <NxDialog
      open={open}
      handleClose={handleClose}
      title={"Choose Locators"}
      buttons={buttons}
    >
      <Grid sx={{ display: "flex", gap: "10px", margin: "15px 0" }}>
        <TextField
          label="Start Range"
          variant="outlined"
          size="small"
          value={startRange}
          onChange={(e) => setStartRange(e.target.value)}
          sx={{ width: "200px" }}
        />
        <TextField
          label="End Range"
          variant="outlined"
          size="small"
          value={endRange}
          onChange={(e) => setEndRange(e.target.value)}
          sx={{ width: "200px" }}
        />
        <NxButton label={"Deactivate Range"} onClick={handleBulkDeactivate} />
      </Grid>

      {/* Virtualized List for Performance Optimization */}
      <List
        height={400} // Adjust as needed
        itemCount={locators.length}
        itemSize={50} // Height of each row
        width="100%"
      >
        {Row}
      </List>
    </NxDialog>
  );
};
