import React from 'react';
import PropTypes from 'prop-types';
import NxCtrlTextInput from '../../ui_components/controls/NxCtrlTextInput';
import NxDialog from '../../ui_components/dialogs/NxDialog';
import './scss/set_password_modal.scss';
import Grid from '../../ui_components/layouts/Grid/Grid.js';
import { validators } from '../../data/DataValidators';

class SetPasswordModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         password: null,
         confirmPassword: null,
         isError: false,
         errMsg: ''
      };
   }

   buttons = [
      {
         label: 'Cancel',
         onClick: this.props.onClose
      },
      {
         label: 'Save',
         onClick: () => this.setPassword()
      }
   ];

   setPassword = () => {
      const isError = this.handleValidation();
      if (isError) {
         this.setState({ isError });
         return;
      }

      this.props.onSetPassword(this.state.password);
      this.setState({ isError: false, errMsg: '' }, () => {
         this.props.onClose();
      });
   };

   validatePassword = (password) => {
      const validatorsArr = [validators.isNull, validators.isEmpty];
      let notValid = false;
      let error = '';
      validatorsArr.forEach((validator) => {
         if (!notValid) {
            notValid = validator.func(password);
            if (notValid) {
               error = validator.msg;
            }
         }
      });
      this.setState({ errMsg: error });
      return notValid;
   };

   handleValidation = () => {
      if (!this.validatePassword(this.state.password) && !this.validatePassword(this.state.confirmPassword)) {
         const matches = this.state.password === this.state.confirmPassword;
         if (!matches) {
            this.setState({ errMsg: "Passwords doesn't match" });
            return true;
         }
         return false;
      }
      return true;
   };

   onChange = (val, field) => {
      this.state[field] = val;
      this.forceUpdate();
   };

   onKeyDown = (e) => {
      if (e.keyCode === 13) {
         this.setPassword();
      }
   };

   render() {
      return (
         <NxDialog
            open={this.props.open}
            handleClose={this.props.onClose}
            width="350px"
            title={`Set user password`}
            buttons={this.buttons}
         >
            <Grid className={'set_password_wrapper'}>
               <NxCtrlTextInput
                  onChange={(val) => this.onChange(val, `password`)}
                  type={'password'}
                  label={'Password'}
                  error={this.state.isError}
                  helperText={this.state.errMsg}
                  value={this.state.password}
                  onKeyDown={this.onKeyDown}
               />
               <NxCtrlTextInput
                  onChange={(val) => this.onChange(val, `confirmPassword`)}
                  label={'Confirm password'}
                  type={'password'}
                  error={this.state.isError}
                  helperText={this.state.errMsg}
                  value={this.state.confirmPassword}
                  onKeyDown={this.onKeyDown}
               />
            </Grid>
         </NxDialog>
      );
   }
}

SetPasswordModal.propTypes = {
   open: PropTypes.bool.isRequired,
   onClose: PropTypes.func.isRequired,
   onDelete: PropTypes.func
};

export default SetPasswordModal;
