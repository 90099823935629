import ds from './data_structure.json';
import DataTypes from './DataTypes';
import React from 'react';
import { setValidations } from './DataValidators';
const { static_configs } = require('./DbConfigs');
const { local_configs } = require('./LocalConfigs');

const data_configs = {};

const parseDataType = (name, type) => {
   if (type.indexOf('char') > -1) {
      return DataTypes.string;
   }

   if (type.indexOf('enum') > -1) {
      return DataTypes.string;
   }

   switch (type) {
      case 'timestamp':
         return DataTypes.time;
      case 'date':
         return DataTypes.date;
      case 'datetime':
         return DataTypes.datetime;
      case 'time':
         return DataTypes.time;
      case 'int':
      case 'double':
         return DataTypes.number;
      case 'tinyint(1)':
      case 'bit(1)':
         return DataTypes.boolean;
      case 'json':
         return DataTypes.json;
      case 'text':
      case 'longtext':
         return DataTypes.string;
   }

   if (
      type.indexOf('int') > -1 ||
      type.indexOf('float') > -1 ||
      type.indexOf('long') > -1 ||
      type.indexOf('decimal') > -1
   ) {
      return DataTypes.number;
   }

   console.error(`New datatype for field : ${name} that is type : ${type}`);
   return DataTypes.string;
};

const capitalizeFirstLetter = (string) => {
   if (string.length) {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }
};

const generateFieldsName = (label) => {
   if (label.includes('_')) {
      const parts = label.split('_');
      return parts.map((part) => capitalizeFirstLetter(part)).join(' ');
   } else {
      return capitalizeFirstLetter(label);
   }
};

const handleCustomRenderCell = (field) => {
   if (!field.hasOwnProperty('renderCell')) {
      if (field.Field.toLowerCase().includes('email')) {
         field.renderCell = (params) => {
            return (
               <a style={{ textDecoration: 'none' }} href={`mailto:${params.value}`}>
                  {params.value}
               </a>
            );
         };
      }
      if (field.Field.toLowerCase().includes('phone')) {
         field.renderCell = (params) => {
            return (
               <a style={{ textDecoration: 'none' }} href={`tel:${params.value}`}>
                  {params.value}
               </a>
            );
         };
      }
   }
};

const data_structure = Object.assign(ds, local_configs);

Object.keys(data_structure).forEach((table) => {
   data_configs[table] = {};
   let fields = data_structure[table].fields;
   let curConfig = data_configs[table];
   curConfig.fields = {};
   curConfig.fieldsArr = [];
   if (static_configs[table]) {
      curConfig.ignoreValidation = static_configs[table].ignoreValidation;
      curConfig.sorting = static_configs[table].sorting;
      curConfig.filters = static_configs[table].filters;
      curConfig.additionalData = static_configs[table].additionalData;
      curConfig.errorHandling = static_configs[table].errorHandling;
      curConfig.searchFields = static_configs[table].searchFields;
      curConfig.exportExcludeFields = static_configs[table].exportExcludeFields;
      curConfig.breadCrumbLabel = static_configs[table].breadCrumbLabel;
      curConfig.id = static_configs[table].id;
   }

   fields.forEach((field) => {
      let curField = {
         ...field,
         field: field.Field,
         name: field.Field,
         label: generateFieldsName(field.Field),
         nullable: field.Null != 'NO',
         datatype: parseDataType(field.Field, field.Type)
      };

      handleCustomRenderCell(curField);

      if (static_configs[table] && static_configs[table].fields[field['Field']]) {
         curField = Object.assign(curField, static_configs[table].fields[field['Field']]);
      }
      if (curField.datatype === DataTypes.number) {
         if (!curField.hasOwnProperty('min')) {
            curField.min = 0;
         }
      }
      if (curField.hasOwnProperty('defaultValue') == false && curField.datatype == DataTypes.boolean) {
         curField.defaultValue = false;
      }
      if (
         curField.hasOwnProperty('defaultValue') == false &&
         (curField.datatype == DataTypes.date || curField.datatype == DataTypes.datetime)
      ) {
         let date = new Date();
         curField.timezone = 'UTC';
         curField.defaultValue = `${date.getUTCFullYear()}-${
            date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
         }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
      }
      if (curField.datatype == 'time') {
         curField.timezone = 'UTC';
      }
      if (curField.field == 'updated_at') {
         curField.defaultValue = null;
      }
      if (curField.hasOwnProperty('defaultValue') == false && curField.datatype == DataTypes.color) {
         curField.defaultValue = '#000';
      }
      curConfig.fields[field['Field']] = curField;
      curConfig.fieldsArr.push(curField);
   });
   setValidations(data_configs[table]);
});

export default data_configs;
