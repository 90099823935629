import React, { Fragment } from 'react';
import NxContent from '../../../nix_components/components/layouts/NxContent';
import PasswordIcon from '@mui/icons-material/Password';
import SetPasswordModal from '../../../nix_components/components/modals/SetPasswordModal';
import NxIconButton from '../../../nix_components/ui_components/controls/NxIconButton';

export default class Users extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         passwordItem: null,
         loaded: false
      };
   }

   buttons = {
      setPassword: (params) => {
         return (
            <NxIconButton
               tooltip="Set Password"
               aria-label="edit"
               color="default"
               onClick={() => this.setState({ passwordItem: params.row })}
            >
               <PasswordIcon />
            </NxIconButton>
         );
      }
   };

   formConfig = {
      rows: [['is_active'], ['email', {}]]
   };

   gridProps = {
      fields: ['email', 'created_at']
   };

   setPassword = (password, user) => {
      global.UF.makeRequest(
         `POST`,
         `/api/set-password`,
         { id: user.id, password: password },
         true,
         (success) => {
            this.setState({ passwordItem: null });
         },
         (error) => {
            if (error) {
               console.error(error);
            }
         }
      );
   };

   renderSetPasswordModal = () => {
      return (
         <SetPasswordModal
            open={this.state.passwordItem !== null}
            onClose={() => this.setState({ passwordItem: null })}
            onSetPassword={(password) => this.setPassword(password, this.state.passwordItem)}
         />
      );
   };

   render() {
      return (
         <Fragment>
            {this.state.passwordItem && this.renderSetPasswordModal()}
            <NxContent
               table="users"
               title="Users"
               gridProps={this.gridProps}
               buttons={this.buttons}
               edit={true}
               delete={true}
               formConfig={this.formConfig}
               add={true}
            />
         </Fragment>
      );
   }
}
