import React, { useState, useMemo, useCallback } from 'react';
import NxDialog from '../../../../../../nix_components/ui_components/dialogs/NxDialog';
import { Grid, Typography, FormControlLabel, Switch, TextField } from '@mui/material';
import NxButton from '../../../../../../nix_components/ui_components/controls/NxButton';
import { FixedSizeList as List } from 'react-window';

export const DeactivateLocators = ({ open, handleClose, treeData, handleFetchDepartmantTree }) => {
   const [locators, setLocators] = useState(() => {
      return treeData.children.map((locator) => ({
         id: locator.id,
         code: locator.code,
         is_active: locator.is_active
      }));
   });

   const [startRange, setStartRange] = useState('');
   const [endRange, setEndRange] = useState('');

   const handleDeactivate = useCallback(async () => {
      setLocators((prevLocators) => {
         const updatedLocators = prevLocators.map((locator) => ({
            id: locator.id,
            is_active: locator.is_active
         }));

         global.UF.makeRequest_v2(`POST`, `/api/location/deactivate`, { locators: updatedLocators })
            .then(async (response) => {
               if (!response.ok) {
                  global.UF.handleError(response);
                  return;
               }

               await handleFetchDepartmantTree();
               global.UF.handleSuccess('Locators successfully updated!');
               handleClose?.();
            })
            .catch((error) => {
               console.error('Deactivate request failed:', error);
               global.UF.handleError('Failed to deactivate locators.');
            });

         return prevLocators; // ✅ Ensures state stays consistent
      });
   }, []);

   const buttons = useMemo(() => {
      return [
         {
            label: 'Cancel',
            onClick: handleClose
         },
         {
            label: 'Update Locators',
            onClick: handleDeactivate
         }
      ];
   }, []);

   const handleDeactivateChange = (e, locatorCode) => {
      setLocators((prev) =>
         prev.map((item) => (item.code === locatorCode ? { ...item, is_active: e.target.checked ? 1 : 0 } : item))
      );
   };

   const handleBulkDeactivate = () => {
      if (!startRange || !endRange) {
         global.UF.handleError('Please enter a valid range in the correct format.');
         return;
      }

      // Ensure input follows the locator format
      const locatorPattern = /^\d{2}-\d{3}-\d{3}-\d{3}-\d{2}$/;
      if (!locatorPattern.test(startRange) || !locatorPattern.test(endRange)) {
         global.UF.handleError('Invalid format! Use: ??-???-???-???-??');
         return;
      }

      const startNumbers = startRange.match(/\d+/g);
      const endNumbers = endRange.match(/\d+/g);

      if (!startNumbers || !endNumbers || startNumbers.length < 5 || endNumbers.length < 5) {
         global.UF.handleError('Invalid range values.');
         return;
      }

      // Extract relevant segments
      const prefixStart = startNumbers.slice(0, 3).join('-'); // First three parts (00-123-002)
      const prefixEnd = endNumbers.slice(0, 3).join('-'); // First three parts of end

      const firstNumStart = parseInt(startNumbers[3], 10); // Fourth segment (e.g., 001)
      const secondNumStart = parseInt(startNumbers[4], 10); // Fifth segment (e.g., 00)

      const firstNumEnd = parseInt(endNumbers[3], 10); // Fourth segment (e.g., 002)
      const secondNumEnd = parseInt(endNumbers[4], 10); // Fifth segment (e.g., 00)

      if (
         prefixStart !== prefixEnd ||
         isNaN(firstNumStart) ||
         isNaN(secondNumStart) ||
         isNaN(firstNumEnd) ||
         isNaN(secondNumEnd)
      ) {
         global.UF.handleError('Invalid range values. Ensure the prefix matches.');
         return;
      }

      if (firstNumStart > firstNumEnd || (firstNumStart === firstNumEnd && secondNumStart > secondNumEnd)) {
         global.UF.handleError('Start range must be less than or equal to the end range.');
         return;
      }

      setLocators((prev) =>
         prev.map((item) => {
            const numbers = item.code.match(/\d+/g);

            if (numbers && numbers.length >= 5) {
               const prefixItem = numbers.slice(0, 3).join('-'); // First three segments of item
               const firstNum = parseInt(numbers[3], 10);
               const secondNum = parseInt(numbers[4], 10);

               if (
                  prefixItem === prefixStart && // Ensure prefix matches
                  (firstNum > firstNumStart || (firstNum === firstNumStart && secondNum >= secondNumStart)) &&
                  (firstNum < firstNumEnd || (firstNum === firstNumEnd && secondNum <= secondNumEnd))
               ) {
                  return { ...item, is_active: 0 };
               }
            }

            return item;
         })
      );
   };

   // Virtualized Row Component
   const Row = ({ index, style }) => {
      const locator = locators[index];
      return (
         <Grid container spacing={2} style={style} alignItems="center">
            <Grid item xs={6}>
               <Typography variant="subtitle1" fontWeight={600} color={'rgba(46, 95, 255, 0.7)'}>
                  {locator.code}
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <FormControlLabel
                  control={
                     <Switch
                        checked={Boolean(locator.is_active)}
                        onChange={(e) => handleDeactivateChange(e, locator.code)}
                        color="primary"
                     />
                  }
                  label="Active"
               />
            </Grid>
         </Grid>
      );
   };

   return (
      <NxDialog open={open} handleClose={handleClose} title={'Deactivate Locators'} buttons={buttons}>
         <Grid sx={{ display: 'flex', gap: '10px', margin: '15px 0' }}>
            <TextField
               label="Start Range"
               variant="outlined"
               size="small"
               value={startRange}
               onChange={(e) => setStartRange(e.target.value)}
               sx={{ width: '200px' }}
            />
            <TextField
               label="End Range"
               variant="outlined"
               size="small"
               value={endRange}
               onChange={(e) => setEndRange(e.target.value)}
               sx={{ width: '200px' }}
            />
            <NxButton label={'Deactivate Range'} onClick={handleBulkDeactivate} />
         </Grid>

         {/* Virtualized List for Performance Optimization */}
         <List
            height={400} // Adjust as needed
            itemCount={locators.length}
            itemSize={50} // Height of each row
            width="100%"
         >
            {Row}
         </List>
      </NxDialog>
   );
};
