import React from "react";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import "./scss/controls.scss";

function Nx_Ctrl_Checkbox(props) {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className={`nx_checkbox ${props.error ? "error" : ""}`}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value ? !!props.value : false}
            onChange={(event) => props.onChange(event.target.checked)}
            size={props.size}
            error={`${props.error}`}
            id={props.fieldConfig ? props.fieldConfig.label : props.label}
          />
        }
        label={props.fieldConfig ? props.fieldConfig.label : props.label}
      />
      <FormHelperText className={props.error ? "error" : ""}>
        {props.helperText}
      </FormHelperText>
    </FormControl>
  );
}

Nx_Ctrl_Checkbox.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
};

Nx_Ctrl_Checkbox.defaultProps = {
  label: "",
  size: "small",
};

export default Nx_Ctrl_Checkbox;
