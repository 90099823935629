import React, { Fragment } from "react";
import NxContent from "../../../../../nix_components/components/layouts/NxContent";
import { AddDepartmant } from "./components/AddDepartmant";
import withRouter from "../../../../../nix_components/components/utils/withRouter";
import { DepartmantTree } from "./components/DepartmantTree";
import NxButton from "../../../../../nix_components/ui_components/controls/NxButton";
import NxDialog from "../../../../../nix_components/ui_components/dialogs/NxDialog";
import { Grid, Typography } from "@mui/material";
import NxCtrlSelect from "../../../../../nix_components/ui_components/controls/NxCtrlSelect";
import NxButtonMenu from "../../../../../nix_components/ui_components/controls/NxButtonMenu";
import { CheckQuantitiesDialog } from "./components/CheckQuantitiesDialog";

class Departmants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      showSummaryDialog: false,
      dialogContent: null,
      departmantId: null,
      exportDepartmantsModal: false,
      choosenDepartmants: [],
      choosenFields: [],
      checkQuantitiesDialog: false,
      exportLocatorsModal: false,
      exportQuantityCheckReport: false,
      exportUnresolvedLocators: false,
      exportSummaryReport: false,
    };

    if (global.UF.dataProvider.datastructure["view_departmants"].filters) {
      global.UF.dataProvider.datastructure[
        "view_departmants"
      ].filters.id_project = this.props.idProject;
    } else {
      global.UF.dataProvider.datastructure["view_departmants"].filters = {
        id_project: this.props.idProject,
      };
    }
  }

  tools = {
    exports: () => {
      return (
        <NxButtonMenu
          label={"Exports"}
          menuItems={[
            {
              label: "Export Output File",
              onClick: () => this.setState({ exportDepartmantsModal: true }),
            },
            {
              label: "Export Locators",
              onClick: () => this.setState({ exportLocatorsModal: true }),
            },
            {
              label: "Export Total Locators Report",
              onClick: () => this.handleExportTotalLocators(false),
              // onClick: this.handleExportSummaryReport
            },
            {
              label: "Export Quantity Check Report",
              onClick: () => this.setState({ exportQuantityCheckReport: true }),
            },
            {
              label: "Export Errored Items Summarized",
              onClick: () => this.handleExportErroredItems(false),
            },
            {
              label: "Export Errored Items Detailed",
              onClick: () => this.handleExportErroredItems(true),
            },
            {
              label: "Export Unresolved Locators",
              onClick: () => this.setState({ exportUnresolvedLocators: true }),
            },
            {
              label: "Export Summary Report",
              onClick: () => this.setState({ exportSummaryReport: true }),
              // onClick: this.handleExportSummaryReport
            },
          ]}
        />
      );
    },
    import: () => {
      return (
        <NxButton
          label={"Import Expected Quantities"}
          onClick={() => this.setState({ checkQuantitiesDialog: true })}
        />
      );
    },
    finishProject: () => {
      return (
        <NxButton label={"Finish Project"} onClick={this.handleFinishProject} />
      );
    },
  };

  exportLocatorsButtons = [
    {
      label: "Cancel",
      onClick: () => {
        this.setState({ exportLocatorsModal: false });
      },
    },
    {
      label: "Export",
      onClick: () => this.handleExportLocators(),
    },
  ];

  exportUnresolvedLocatorsButtons = [
    {
      label: "Cancel",
      onClick: () => {
        this.setState({ exportUnresolvedLocators: false });
      },
    },
    {
      label: "Export",
      onClick: () => this.handleExportUnresolvedLocators(),
    },
  ];

  exportQuantityCheckReportButtons = [
    {
      label: "Cancel",
      onClick: () => {
        this.setState({ exportQuantityCheckReport: false });
      },
    },
    {
      label: "Export",
      onClick: () => this.handleExportQuantityCheckReport(),
    },
  ];

  exportDepartmantsButtons = [
    {
      label: "Cancel",
      onClick: () => {
        this.setState({ exportDepartmantsModal: false });
      },
    },
    {
      label: "Export",
      onClick: () => this.handleExportDeparmants(),
    },
  ];

  exportSummaryReportButtons = [
    {
      label: "Cancel",
      onClick: () => {
        this.setState({ exportSummaryReport: false });
      },
    },
    {
      label: "Export",
      onClick: () => this.handleExportSummaryReport(),
    },
  ];

  delimiters = [
    { id: ",", label: "Comma - ," },
    { id: ";", label: "Semicolon - ;" },
    { id: "|", label: "Pipe - |" },
    { id: "\t", label: "Tab" },
  ];

  fields = [
    { id: "si.code", label: "Code" },
    { id: "i.key", label: "Key" },
    { id: "i.sku", label: "SKU" },
    { id: "i.description", label: "Description" },
    { id: "si.quantity", label: "Quantity" },
    { id: "i.price", label: "Price" },
    { id: "current_state", label: "Current quantity" },
  ];

  buttons = {};

  fetchData = () => {};

  gridProps = {
    fields: [
      "name",
      "prefix",
      "errored_items_count",
      "duplicate_locators_count",
      "unresolved_locators_count",
      "created_at",
    ],
  };

  handleExportQuantityCheckReport = async () => {
    try {
      if (!this.state.choosenDepartmants.length) {
        global.UF.handleError(`Please choose departmants to export`);
        return;
      }

      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/locators/check_quantities`,
        {
          departments: this.state.choosenDepartmants,
        },
        true,
        true,
        true,
        "buffer"
      );

      if (!response.ok) {
        global.UF.handleError("Error generating quantity check report");
        return;
      }

      let blob = null;

      blob = response.body;

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "quantity_check.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error:", error);
      global.UF.handleError("Error processing file");
    }
  };

  handleFinishProject = async () => {
    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/projects/finish`,
      { idProject: this.props.idProject },
      true,
      true,
      false
    );

    if (!response.ok) {
      global.UF.handleError(
        `Something went wrong while trying to finish project`
      );
      console.error(response);
      return;
    }

    const body = response.body;

    if (body.projectUpdated) {
      global.UF.handleSuccess(`Project has been successfully finished`);
      return;
    }

    this.setState({ showSummaryDialog: true, dialogContent: body });
  };

  handleRenderSummaryDialog = () => {
    const { dialogContent } = this.state;
    return (
      <NxDialog
        open={this.state.showSummaryDialog}
        handleClose={() => this.setState({ showSummaryDialog: false })}
        title={"Project Summary"}
      >
        <Grid
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            backgroundColor: "#f9f9f9",
            borderRadius: "16px",
          }}
        >
          <Typography variant="body1" sx={{ color: "red", fontWeight: 500 }}>
            🔴 Not Resolved Locators: {dialogContent.hasMissing}
          </Typography>

          <Typography variant="body1" sx={{ color: "orange", fontWeight: 500 }}>
            🟠 Errored Locators: {dialogContent.hasErrors}
          </Typography>

          <Typography variant="body1" sx={{ color: "blue", fontWeight: 500 }}>
            🔵 Duplicate Locators: {dialogContent.hasDuplicates}
          </Typography>
        </Grid>
      </NxDialog>
    );
  };

  handleExportDeparmants = async () => {
    if (!this.state.choosenDepartmants.length) {
      global.UF.handleError(`Please choose departmants to export`);
      return;
    }

    if (!this.state.choosenFields.length) {
      global.UF.handleError(`Please choose fields to export`);
      return;
    }

    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/departmants/export`,
        {
          departmants: this.state.choosenDepartmants,
          delimiter: this.state.csvDelimiter,
          fields: this.state.choosenFields,
        },
        true,
        true,
        true
      );

      if (!response || !response.text) {
        global.UF.handleError(
          `Something went wrong while trying to export departmants`
        );
        console.error(response);
        return;
      }

      // Convert response data into a Blob
      const blob = new Blob([response.text], { type: "text/plain" });

      // Create a downloadable URL
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.download = `DepartmantsExport.txt`;

      // Trigger download
      a.click();

      // Cleanup
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export departmants`
      );
      console.error(err);
    }
  };

  handleExportLocators = async () => {
    if (!this.state.choosenDepartmants.length) {
      global.UF.handleError(`Please choose departmants to export`);
      return;
    }

    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/locators/export`,
        {
          departmants: this.state.choosenDepartmants,
        },
        true,
        true,
        true,
        "buffer"
      );

      if (!response.ok) {
        global.UF.handleError(
          `Something went wrong while trying to export locators`
        );
        console.error(response);
        return;
      }

      let blob = null;

      blob = response.body;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `Locators.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export locators`
      );
      console.error(err);
      return;
    }
  };

  handleExportErroredItems = async (detailed = false) => {
    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/errored_items/export`,
        {
          idProject: this.props.idProject,
          detailed: detailed,
        },
        true,
        true,
        true,
        "buffer"
      );

      if (!response.ok) {
        global.UF.handleError(
          `Something went wrong while trying to export errored items`
        );
        console.error(response);
        return;
      }

      let blob = null;

      blob = response.body;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `ErroredItemsExport.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export errored items`
      );
      console.error(err);
      return;
    }
  };

  handleExportTotalLocators = async (detailed = false) => {
    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/total_locators/export`,
        {
          idProject: this.props.idProject,
        },
        true,
        true,
        true,
        "buffer"
      );

      if (!response.ok) {
        global.UF.handleError(
          `Something went wrong while trying to export errored items`
        );
        console.error(response);
        return;
      }

      let blob = null;

      blob = response.body;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `TotalLocators.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export errored items`
      );
      console.error(err);
      return;
    }
  };

  handleExportSummaryReport = async () => {
    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/summary_report/export`,
        {
          idProject: this.props.idProject,
          departmants: this.state.choosenDepartmants,
        },
        true,
        true,
        true,
        "buffer"
      );

      if (!response.ok) {
        global.UF.handleError(
          `Something went wrong while trying to export summary report`
        );
        console.error(response);
        return;
      }

      let blob = null;

      blob = response.body;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = `SummaryReport.xlsx`;
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export summary report`
      );
      console.error(err);
      return;
    }
  };

  handleExportUnresolvedLocators = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch("/api/unresolved_locators/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          idProject: this.props.idProject,
          departmants: this.state.choosenDepartmants,
        }),
      });

      if (!response.ok) {
        global.UF.handleError(
          `Something went wrong while trying to export unresolved locators`
        );
        if (response.status === 401) {
          global.UF.logout();
        }

        console.error("Error:", response);
        return;
      }

      const blob = await response.blob(); // Get PDF as binary data

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.download = "unresolved_locators.pdf";
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    } catch (err) {
      global.UF.handleError(
        `Something went wrong while trying to export unresolved locators`
      );
      console.error(err);
    }
  };

  handleCloseAddLocatorDialog = () => {
    this.setState({ showDialog: false }, async () => {
      this.fetchData();
      await global.UF.dataProvider.referenceProvider.get_v2(
        `departmants`,
        true
      );
    });
  };

  renderAddLocatorsForm = () => {
    return (
      <AddDepartmant
        open={this.state.showDialog}
        idProject={this.props.idProject}
        prefixProject={this.props.prefixProject}
        handleClose={this.handleCloseAddLocatorDialog}
      />
    );
  };

  renderExportDepartmantsModal = () => {
    return (
      <NxDialog
        open={true}
        handleClose={() => this.setState({ exportDepartmantsModal: false })}
        title={"Export Departmants"}
        buttons={this.exportDepartmantsButtons}
      >
        <Grid
          container
          spacing={3}
          sx={{
            padding: "60px",
            gridTemplateColumns: "1fr 1fr 1fr",
            display: "grid",
            gap: "20px",
          }}
        >
          <NxCtrlSelect
            value={this.state.choosenDepartmants}
            onChange={(value) => {
              this.setState({ choosenDepartmants: value });
            }}
            fieldConfig={{
              reference: "departmants",
              label: "Choose departmants",
              multiple: true,
              reference_filter: (items) => {
                return items.filter(
                  (item) => item.id_project == this.props.idProject
                );
              },
            }}
          />
          <NxCtrlSelect
            data={this.delimiters}
            value={this.state.csvDelimiter}
            onChange={(value) => this.setState({ csvDelimiter: value })}
            label="Choose delimiter"
          />
          <NxCtrlSelect
            data={this.fields}
            value={this.state.choosenFields}
            onChange={(value) => this.setState({ choosenFields: value })}
            label="Choose fields"
            fieldConfig={{
              multiple: true,
            }}
          />
        </Grid>
      </NxDialog>
    );
  };

  renderUnresolvedLocatorsModal = () => {
    return (
      <NxDialog
        open={true}
        handleClose={() => this.setState({ exportUnresolvedLocators: false })}
        title={"Export Unresolved Locators"}
        buttons={this.exportUnresolvedLocatorsButtons}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "60px",
            gridTemplateColumns: "1fr 1fr",
            display: "grid",
            gap: "20px",
          }}
        >
          <NxCtrlSelect
            value={this.state.choosenDepartmants}
            onChange={(value) => {
              this.setState({ choosenDepartmants: value });
            }}
            fieldConfig={{
              reference: "departmants",
              label: "Choose departmants",
              multiple: true,
              reference_filter: (items) => {
                return items.filter(
                  (item) => item.id_project == this.props.idProject
                );
              },
            }}
          />
        </Grid>
      </NxDialog>
    );
  };

  renderSummaryReportModal = () => {
    return (
      <NxDialog
        open={true}
        handleClose={() => this.setState({ exportSummaryReport: false })}
        title={"Export Summary Report"}
        buttons={this.exportSummaryReportButtons}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "60px",
            gridTemplateColumns: "1fr 1fr",
            display: "grid",
            gap: "20px",
          }}
        >
          <NxCtrlSelect
            value={this.state.choosenDepartmants}
            onChange={(value) => {
              this.setState({ choosenDepartmants: value });
            }}
            fieldConfig={{
              reference: "departmants",
              label: "Choose departmants",
              multiple: true,
              reference_filter: (items) => {
                return items.filter(
                  (item) => item.id_project == this.props.idProject
                );
              },
            }}
          />
        </Grid>
      </NxDialog>
    );
  };

  renderExportQuantityCheckReport = () => {
    return (
      <NxDialog
        open={true}
        handleClose={() => this.setState({ exportQuantityCheckReport: false })}
        title={"Export Quantity Check Report"}
        buttons={this.exportQuantityCheckReportButtons}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "60px",
            gridTemplateColumns: "1fr 1fr",
            display: "grid",
            gap: "20px",
          }}
        >
          <NxCtrlSelect
            value={this.state.choosenDepartmants}
            onChange={(value) => {
              this.setState({ choosenDepartmants: value });
            }}
            fieldConfig={{
              reference: "departmants",
              label: "Choose departmants",
              multiple: true,
              reference_filter: (items) => {
                return items.filter(
                  (item) => item.id_project == this.props.idProject
                );
              },
            }}
          />
        </Grid>
      </NxDialog>
    );
  };

  renderExportLocatorsModal = () => {
    return (
      <NxDialog
        open={true}
        handleClose={() => this.setState({ exportLocatorsModal: false })}
        title={"Export Locators"}
        buttons={this.exportLocatorsButtons}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "60px",
            gridTemplateColumns: "1fr 1fr",
            display: "grid",
            gap: "20px",
          }}
        >
          <NxCtrlSelect
            value={this.state.choosenDepartmants}
            onChange={(value) => {
              this.setState({ choosenDepartmants: value });
            }}
            fieldConfig={{
              reference: "departmants",
              label: "Choose departmants",
              multiple: true,
              reference_filter: (items) => {
                return items.filter(
                  (item) => item.id_project == this.props.idProject
                );
              },
            }}
          />
        </Grid>
      </NxDialog>
    );
  };

  handleRowClick = ({ row }) => {
    const params = this.props.router.params;
    const pathname = `/clients/${params.id}/stores/${params.idStore}/projects/${params.idProject}/departmants/${row.id}`;
    this.props.router.navigate(pathname);
  };

  componentDidMount = () => {
    const params = this.props.router.params;

    if (params.hasOwnProperty("tabId")) {
      this.setState({ departmantId: params.tabId });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    const nextParams = nextProps.router.params;

    if (this.state.departmantId && !nextParams.hasOwnProperty("tabId")) {
      this.setState({ departmantId: null });
      return true;
    }

    if (nextParams.hasOwnProperty("tabId") && !this.state.departmantId) {
      const departmantId = nextParams.tabId;

      this.setState({ departmantId });
      return true;
    }

    return true;
  };

  renderDepartmantTree = () => {
    if (!this.state.departmantId) {
      return null;
    }

    return (
      <DepartmantTree
        departmantId={this.state.departmantId}
        idProject={this.props.idProject}
      />
    );
  };

  renderCheckQuantitiesDialog = () => {
    return (
      <CheckQuantitiesDialog
        open={this.state.checkQuantitiesDialog}
        handleClose={() => this.setState({ checkQuantitiesDialog: false })}
      />
    );
  };

  onDelete = async (dataItem, callback) => {
    const response = await global.UF.dataProvider.get_v2(`departmants`, {
      id: dataItem.id,
    });

    if (!response.length) {
      global.UF.handleError(`Departmant not found`);
      return;
    }

    const departmant = response[0];

    departmant.Delete(() => {
      if (callback) {
        callback();
      }
    });
  };

  render() {
    if (this.state.departmantId) {
      return this.renderDepartmantTree();
    }

    return (
      <Fragment>
        {this.state.showDialog && this.renderAddLocatorsForm()}
        {this.state.showSummaryDialog && this.handleRenderSummaryDialog()}
        {this.state.exportDepartmantsModal &&
          this.renderExportDepartmantsModal()}
        {this.state.checkQuantitiesDialog && this.renderCheckQuantitiesDialog()}
        {this.state.exportLocatorsModal && this.renderExportLocatorsModal()}
        {this.state.exportQuantityCheckReport &&
          this.renderExportQuantityCheckReport()}
        {this.state.exportUnresolvedLocators &&
          this.renderUnresolvedLocatorsModal()}
        {this.state.exportSummaryReport && this.renderSummaryReportModal()}
        <NxContent
          table="view_departmants"
          title="Departmants"
          gridProps={this.gridProps}
          buttons={this.buttons}
          tools={this.tools}
          delete={true}
          onAdd={() => this.setState({ showDialog: true })}
          add={true}
          onDelete={this.onDelete}
          fetchData={(fetchData) => (this.fetchData = fetchData)}
          onRowClick={this.handleRowClick}
        />
      </Fragment>
    );
  }
}

export default withRouter(Departmants);
