import DataTypes from './DataTypes';

const isNull = (value) => {
   return value == null;
};

const isEmpty = (value) => {
   if (!isNull(value)) {
      return value.trim().length == 0;
   } else {
      return true;
   }
};

const isThreeDigits = (value) => {
   if (value == null) {
      return false;
   }

   return !/^\d{3}$/.test(value.toString().trim());
};

const isNumber = (value) => {
   if (value == null || value === '') {
      return false;
   }

   if (/^\d+$/.test(value.toString().trim())) {
      return false;
   }

   return true;
};

const isNotEmail = (email) => {
   return !String(email)
      .toLowerCase()
      .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
};

const isNotValidPassword = (password) => {
   return !String(password).match(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/
   );
};

const hasSpecialChars = (inputString) => {
   return !String(inputString).match(/[!@#$%^&*(),.?":{}|<>]/);
};

const hasSpecialCharAsFirstChar = (inputString) => {
   const firstChar = String(inputString).charAt(0);
   return firstChar.match(/[!@#$%^&*(),.?":{}|<>]/) !== null;
};

const isDateBeforeStartDate = (start, end) => {
   if (start !== null && end !== null) {
      return new Date(start).getTime() + 86400 > new Date(end).getTime();
   } else {
      return true;
   }
};

const isTimeBeforeStartTime = (start, end) => {
   if (start !== null && end !== null) {
      return new Date(start).getTime() > new Date(end).getTime();
   } else {
      return false;
   }
};

export const isValidISBNs = (isbns, dataItem) => {
   let validation = [];
   if (Array.isArray(isbns)) {
      isbns.forEach((isbn) => {
         isbn = isbn.toString();
         let sum = 0;

         if (isbn.length === 10) {
            for (let i = 0; i < 9; i++) {
               sum += parseInt(isbn[i]) * (10 - i);
            }

            if (isbn[9] === 'X') {
               sum += 10;
            } else {
               sum += parseInt(isbn[9]);
            }

            return sum % 11 == 0;
         }

         if (isbn.length === 13) {
            const last = isbn[12];

            for (let i = 0; i < 12; i++) {
               if (i % 2 === 0) {
                  sum += parseInt(isbn[i]);
               } else {
                  sum += parseInt(isbn[i]) * 3;
               }
            }

            if (sum % 10 == 0) {
               return true;
            }
            if (10 - (sum % 10) == last) {
               return true;
            }

            validation.push(false);
         }

         validation.push(false);
      });
      return validation.includes(false);
   }
   return false;
};

const isDateBeforeToday = (value) => {
   if (value !== null && value !== undefined) {
      return new Date().getTime() > new Date(value).getTime();
   } else {
      return true;
   }
};

const isNotWebsite = (value) => {
   const websiteConfig =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-_]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
   if (!isNull(value)) {
      return !websiteConfig.test(value);
   } else {
      return true;
   }
};

const isNegative = (value) => {
   if (value !== null && value !== undefined) {
      return !Number(value) < 0;
   } else {
      return true;
   }
};

const isDateSevenDaysAfterToday = (value) => {
   return (new Date(value) - new Date()) / (24 * 60 * 60 * 1000) < 7;
};

const validators = {
   isNull: {
      msg: 'Field is required',
      func: isNull
   },
   isEmpty: {
      msg: 'Field cannot be empty',
      func: isEmpty
   },
   isNotEmail: {
      msg: 'Email must be in correct email format',
      func: isNotEmail
   },
   isWebsite: {
      msg: 'Website must be in correct url format',
      func: isNotWebsite
   },
   isDateBeforeToday: {
      msg: `Date can't be before today`,
      func: isDateBeforeToday
   },
   isNegative: {
      msg: `Value cannot be negative`,
      func: isNegative
   },
   isDateBeforeStartDate: {
      name: 'beforeStartDate',
      msg: `Date can't be before start date`,
      func: isDateBeforeStartDate
   },
   isTimeBeforeStartTime: {
      name: 'beforeStartTime',
      msg: `Time can't be before start time`,
      func: isTimeBeforeStartTime
   },
   isValidISBNs: {
      name: 'validISBNs',
      msg: `Invalid ISBNs`,
      func: isValidISBNs
   },
   isNotValidPassword: {
      name: 'isNotValidPassword',
      msg: `Password need to has at least 8 chars, 1 char, 1 number and 1 special char! \n Example format : Password1!`,
      func: isNotValidPassword
   },
   hasSpecialChars: {
      msg: "Input can't indlude special characters!",
      func: hasSpecialChars
   },
   hasSpecialCharAsFirstChar: {
      msg: 'Special character cannot be first character!',
      func: hasSpecialCharAsFirstChar
   },
   isDateSevenDaysAfterToday: {
      msg: 'Date must be 7 days after today',
      func: isDateSevenDaysAfterToday
   },
   isThreeDigits: {
      msg: 'The value must consist of exactly three digits',
      func: isThreeDigits
   },
   isNumber: {
      msg: `The value must contain only numeric characters (0-9).`,
      func: isNumber
   }
};

const setCustomValidations = (field) => {
   if (field.datatype === DataTypes.number) {
      !field.nullable && field.validators.push(validators.isNegative);
   }
   if (field.name.includes('email')) {
      if (field.validators.length) field.validators.push(validators.isNotEmail);
   }
   if (field.name.includes('website')) {
      field.validators.push(validators.isWebsite);
   }
   if (field.name === 'password') {
      field.validators.push(validators.isNotValidPassword);
   }
};

const setValidations = (table_structure) => {
   table_structure.fieldsArr.forEach((field) => {
      let validatorArr = [];
      if (field.nullable == false && validatorArr.indexOf(validators.isNull) < 0) {
         validatorArr.push(validators.isNull);
         if (field.datatype === DataTypes.string) {
            validatorArr.push(validators.isEmpty);
         }
      }
      if (field.hasOwnProperty('validators')) {
         field.validators = [...validatorArr, ...field.validators];
      } else {
         field.validators = validatorArr;
      }

      setCustomValidations(field);
      if (!field.hasOwnProperty('validate')) {
         field.validate = async (dataItem, value, returnMsg = true) => {
            let isNotValid = false;

            for (const validator of field.validators) {
               if (isNotValid === false) {
                  const valid = await validator.func(value, dataItem);
                  if (valid) {
                     if (returnMsg) {
                        isNotValid = validator.msg;
                     } else {
                        isNotValid = true;
                     }
                  }
               }
            }
            return isNotValid;
         };
      }
   });

   table_structure.validate = async (dataItem) => {
      let isValid = true;
      const validationPromises = table_structure.fieldsArr.map(async (field) => {
         if (!table_structure.ignoreValidation?.includes(field.name)) {
            const validating = await field.validate(dataItem, dataItem[field.name], true);
            if (validating) {
               isValid = false;
               global.UF.setAlertVisibility(true, `${field.label} - ${validating}`, 'info');
               console.error(`The validation failed for field: ${field.name}`);
            }
         }
         return isValid;
      });

      await Promise.all(validationPromises);

      return isValid;
   };
};

export { setValidations, validators };
