import React, { Fragment } from "react";
import NxContent from "../../../../../nix_components/components/layouts/NxContent";
import { ProductImportStepper } from "./components/ProductImportDialog";
import NxIconButton from "../../../../../nix_components/ui_components/controls/NxIconButton";
import UploadIcon from "@mui/icons-material/Upload";
import withRouter from "../../../../../nix_components/components/utils/withRouter";
import { DeleteOutline } from "@mui/icons-material";
import NxConfirm from "../../../../../nix_components/ui_components/alerts/NxConfirm";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      showDialogDelete: false,
      isLoaded: false,
      idClient: null,
    };
  }

  tools = {
    uploadItems: () => {
      return (
        <NxIconButton onClick={() => this.setState({ showDialog: true })}>
          <UploadIcon />
        </NxIconButton>
      );
    },

    deleteProducts: () => {
      return (
        <NxIconButton onClick={() => this.setState({ showDialogDelete: true })}>
          <DeleteOutline />
        </NxIconButton>
      );
    },
  };

  formConfig = {
    rows: [
      ["code", "sku", "key"],
      ["quantity", "description"],
      ["price", {}],
    ],
  };

  buttons = {};

  fetchData = () => {};

  gridProps = {
    fields: [
      "code",
      "sku",
      "key",
      "description",
      "price",
      "quantity",
      "created_at",
    ],
  };

  renderDeleteConfirmModal = () => {
    return (
      <NxConfirm
        show={true}
        text={`Are you sure you want to delete all products`}
        onClick={this.handleDeleteSessionLocator}
        onClose={() => this.setState({ showDialogDelete: false })}
      />
    );
  };
  handleDeleteSessionLocator = async () => {
    console.log(this.state.idClient, "Idklijenta");

    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/delete_products`,
      {
        client_id: this.state.idClient,
      }
    );

    console.log(response);
    global.UF.handleSuccess("Uspesno ste obrisali producte");
    this.fetchData();
    this.setState({ showDialogDelete: false });
  };

  handleCloseProductImportDialog = () => {
    this.setState({ showDialog: false }, () => {
      this.fetchData();
    });
  };

  renderAddLocatorsForm = () => {
    return (
      <ProductImportStepper
        open={this.state.showDialog}
        idClient={this.state.idClient}
        handleClose={this.handleCloseProductImportDialog}
      />
    );
  };

  handleBeforeAddNewItem = (dataItem, callback) => {
    dataItem.id_client = this.state.idClient;
    if (callback) {
      callback(dataItem);
    }
  };

  componentDidMount = () => {
    const { router } = this.props;

    if (router.params.id) {
      const filters = global.UF.dataProvider.datastructure["items"].filters;

      if (!filters) {
        global.UF.dataProvider.datastructure["items"].filters = {
          id_client: router.params.id,
        };
        this.setState({ isLoaded: true, idClient: router.params.id });
        return;
      }

      global.UF.dataProvider.datastructure["items"].filters.id_client =
        router.params.id;
      this.setState({ isLoaded: true, idClient: router.params.id });
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.router.params.id !== nextProps.router.params.id) {
      const filters = global.UF.dataProvider.datastructure["items"].filters;

      if (!filters) {
        global.UF.dataProvider.datastructure["items"].filters = {
          id_client: nextProps.router.params.id,
        };
        this.setState({ isLoaded: true, idClient: nextProps.router.params.id });
        return;
      }

      global.UF.dataProvider.datastructure["items"].filters.id_client =
        nextProps.router.params.id;
      this.setState({ isLoaded: true, idClient: nextProps.router.params.id });
      return true;
    }
    return true;
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    return (
      <Fragment>
        {this.state.showDialog && this.renderAddLocatorsForm()}
        {this.state.showDialogDelete && this.renderDeleteConfirmModal()}
        <NxContent
          table="items"
          title="Products"
          gridProps={this.gridProps}
          buttons={this.buttons}
          tools={this.tools}
          delete={true}
          add={true}
          edit={true}
          beforeAddNew={this.handleBeforeAddNewItem}
          fetchData={(fetchData) => (this.fetchData = fetchData)}
          formConfig={this.formConfig}
        />
      </Fragment>
    );
  }
}

export default withRouter(Products);
