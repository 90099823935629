import React from 'react';
import NxDialog from '../dialogs/NxDialog';
import Grid from '../layouts/Grid/Grid';
import { Typography } from '@mui/material';

const NxConfirm = ({ show, onClose, text, onNo, onClick }) => {
   const buttons = [
      {
         label: 'No',
         onClick: onNo ? onNo : onClose,
         variant: 'text'
      },
      {
         label: 'Yes',
         onClick: onClick,
         variant: 'text'
      }
   ];

   return (
      <NxDialog open={show} title="Warning!" handleClose={onClose} width={400} buttons={buttons}>
         <Grid>
            <Typography variant="body1" fontWeight={'bold'}>
               {text}
            </Typography>
         </Grid>
      </NxDialog>
   );
};

export default NxConfirm;
