export const local_configs = {
  view_offer_reply_missing_isbn: {
    table_name: "view_offer_reply_missing_isbn",
    fields: [
      {
        Field: "isbn",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "new_otb",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "used_otb",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "count",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
    ],
  },
  view_offer_reply_new_isbn: {
    table_name: "view_offer_reply_new_isbn",
    fields: [
      {
        Field: "isbn",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "count",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
    ],
  },
  view_offer_reply_quantity_issues: {
    table_name: "view_offer_reply_quantity_issues",
    fields: [
      {
        Field: "isbn",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "new_otb",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "count",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "used_otb",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "offer_reply_new_otb",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
      {
        Field: "offer_reply_used_otb",
        Type: "varchar(50)",
        Null: "NO",
        Key: "",
        Default: null,
        Extra: "",
      },
    ],
  },
};
